import { Row, Col, Statistic, Button } from "antd";
import styles from "./kanban.module.css";
import { Chart16, Chart17, Chart18 } from "../../components/charts";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";

const span = { xs: 24, sm: 24, md: 24, lg: 12, xl: 8, xxl: 8 };

export default function Kanban1() {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader
        title="著作权数据统计"
        description="针对入驻园区企业的著作权、软件著作权等数据的统计看板。"
      />
      <div className={styles.timestamp}>
        最后更新时间：2023年6月12日 23:00:00
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className={styles.section}>
            <div className={styles.statistic}>
              <div className={styles.item}>
                <Statistic title="著作权登记数量" value={103} />
                <Button
                  type="primary"
                  onClick={() => navigate("/1/query/copyrights")}
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="软件著作权登记数量" value={684} />
                <Button
                  type="primary"
                  onClick={() => navigate("/1/query/copyrights")}
                >
                  查看详情
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>著作权类型分布</h2>
              <Chart16 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>著作权登记数量（近5年）</h2>
              <Chart17 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>软件著作权登记数量（近5年）</h2>
              <Chart18 />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
