import { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import { range } from 'lodash';

function Chart10() {
  const element = useRef();
  useEffect(() => {
    
    const chart = new Chart(element.current, {
      type: 'line',
      data: {
        labels: range(5).map(index => `${2019 + index}年`),
        datasets: [
          {
            label: '申请数量',
            data: [ 6247, 7213, 8491, 8576, 9812 ],
            fill: true,
            borderColor: '#264653'
          }, {
            label: '注册数量',
            data: [ 5347, 6013, 6904, 7032, 7499 ],
            fill: true,
            borderColor: '#e76f51'
          }
        ]
      },
      options: {
        scales: {
          y: { 
            min: 0,
            // max: 30,
            // ticks: { stepSize: 1 } 
          }
        },
        aspectRatio: 1.5,
        layout: {
          // padding: 16
        },
        plugins: {
          legend: { position: 'bottom' }
        }
      }
    });
    return () => chart.destroy();
  }, []);
  return (
    <canvas ref={element} />
  );
}

export default Chart10;