const trademarks = [
  {
    reg_number: "66308462",
    period: "-",
    id: "62ea03c31f11203fbf774210",
    image_name:
      "https://qxb-img-osscache.qixin.com/official_trademark/02add4ad85e133623f7f452e17062279.jpg",
    name: "图形",
    status: "等待实质审查",
    type_name: "科学仪器",
    products: [
      {
        num: "0910",
        name: "电测量仪器",
      },
      {
        num: "0913",
        name: "电插头",
      },
      {
        num: "0913",
        name: "电开关",
      },
      {
        num: "0902",
        name: "数量显示器",
      },
      {
        num: "0904",
        name: "秤",
      },
      {
        num: "0910",
        name: "教学机器人",
      },
      {
        num: "0905",
        name: "规尺（量具）",
      },
      {
        num: "0920",
        name: "电动防盗装置",
      },
      {
        num: "0906",
        name: "防交通事故用穿戴式反射用品",
      },
      {
        num: "0909",
        name: "液晶投影机",
      },
      {
        num: "0919",
        name: "安全头盔",
      },
      {
        num: "0910",
        name: "联机手环（测量仪器）",
      },
      {
        num: "0912",
        name: "电线",
      },
      {
        num: "0911",
        name: "望远镜",
      },
      {
        num: "0913",
        name: "插线板",
      },
      {
        num: "0919",
        name: "护目镜",
      },
      {
        num: "0919",
        name: "防弹衣",
      },
      {
        num: "0922",
        name: "电池",
      },
      {
        num: "0922",
        name: "蓄电池",
      },
      {
        num: "0922",
        name: "发电用太阳能电池板",
      },
      {
        num: "0922",
        name: "无线充电器",
      },
      {
        num: "0910",
        name: "电度表",
      },
      {
        num: "0921",
        name: "眼镜",
      },
      {
        num: "0901",
        name: "编辑图像、声音和视频用计算机程序",
      },
      {
        num: "0901",
        name: "智能手表（数据处理）",
      },
      {
        num: "0908",
        name: "电视机",
      },
    ],
    company: "苹果（中国）有限公司",
    type_num: "9",
    agent: "直接办理",
    steps: [
      {
        date: "2022-08-01",
        step: "商标注册申请-申请收文",
      },
      {
        date: "2022-08-19",
        step: "商标注册申请-等待受理通知书发文",
      },
      {
        date: "2022-12-08",
        step: "商标注册申请-等待驳回通知发文",
      },
      {
        date: "2023-02-02",
        step: "商标注册申请-等待驳回复审",
      },
    ],
    apply_date: "2022-08-01",
  },
  {
    reg_number: "71943557",
    period: "-",
    id: "6481ad868e6db64710f9cec2",
    image_name:
      "https://qxb-img-osscache.qixin.com/official_trademark/bec2bb85e6bc266ad9ba7b483a4b3135.jpg",
    name: "未家",
    status: "-",
    type_name: "科学仪器",
    products: [
      {
        num: "0901",
        name: "智能手表（数据处理）",
      },
      {
        num: "0901",
        name: "操作系统程序",
      },
      {
        num: "0901",
        name: "接入或进入控制用计算机程序",
      },
      {
        num: "0901",
        name: "可下载的计算机应用软件",
      },
      {
        num: "0902",
        name: "计步器",
      },
      {
        num: "0907",
        name: "穿戴式行动追踪器",
      },
      {
        num: "0907",
        name: "手机",
      },
      {
        num: "0908",
        name: "虚拟现实眼镜",
      },
      {
        num: "0908",
        name: "智能音箱",
      },
      {
        num: "0909",
        name: "数字投影仪",
      },
      {
        num: "0910",
        name: "测量器械和仪器",
      },
      {
        num: "0912",
        name: "电源材料（电线、电缆）",
      },
      {
        num: "0913",
        name: "芯片（集成电路）",
      },
      {
        num: "0913",
        name: "传感器",
      },
      {
        num: "0913",
        name: "遥控装置",
      },
      {
        num: "0913",
        name: "视频显示屏",
      },
      {
        num: "0920",
        name: "报警器",
      },
      {
        num: "0920",
        name: "生物指纹门锁",
      },
      {
        num: "0921",
        name: "3D眼镜",
      },
      {
        num: "0922",
        name: "电池充电器",
      },
    ],
    company: "小米科技有限责任公司",
    type_num: "9",
    agent: "-",
    steps: [],
    apply_date: "2023-05-31",
  },
  {
    reg_number: "71908473",
    period: "-",
    id: "6480b1488e6db64710f98ac6",
    image_name:
      "https://qxb-img-osscache.qixin.com/official_trademark/e4134825fa7b6fc21a194950557e9413.jpg",
    name: "XIAOMI",
    status: "-",
    type_name: "服装鞋帽",
    products: [
      {
        num: "2501",
        name: "成品衣",
      },
      {
        num: "2501",
        name: "服装",
      },
      {
        num: "2502",
        name: "婴儿全套衣",
      },
      {
        num: "2502",
        name: "服装",
      },
      {
        num: "2503",
        name: "驾驶员服装",
      },
      {
        num: "2503",
        name: "服装",
      },
      {
        num: "2504",
        name: "服装",
      },
      {
        num: "2504",
        name: "防雨夹克",
      },
      {
        num: "2505",
        name: "服装",
      },
      {
        num: "2505",
        name: "化装舞会用服装",
      },
      {
        num: "2507",
        name: "鞋",
      },
      {
        num: "2508",
        name: "帽子",
      },
      {
        num: "2509",
        name: "袜",
      },
      {
        num: "2510",
        name: "手套（服装）",
      },
      {
        num: "2511",
        name: "围巾",
      },
      {
        num: "2512",
        name: "衣服背带",
      },
      {
        num: "2513",
        name: "浴帽",
      },
      {
        num: "2513",
        name: "婚纱",
      },
      {
        num: "2513",
        name: "理发用披肩",
      },
      {
        num: "2513",
        name: "服装绶带",
      },
      {
        num: "2513",
        name: "睡眠用眼罩",
      },
      {
        num: "2513",
        name: "神父左臂上佩戴的饰带",
      },
    ],
    company: "小米科技有限责任公司",
    type_num: "25",
    agent: "-",
    steps: [],
    apply_date: "2023-05-30",
  },
  {
    reg_number: "71915546",
    period: "-",
    id: "64804d4a0da7d2e321318628",
    image_name:
      "https://qxb-img-osscache.qixin.com/official_trademark/719d1b116525f07e3c05ad0b371d184e.jpg",
    name: "小米米家",
    status: "-",
    type_name: "金属材料",
    products: [
      {
        num: "0601",
        name: "3D打印机用金属箔或金属粉",
      },
      {
        num: "0602",
        name: "金属喷头",
      },
      {
        num: "0602",
        name: "金属管道",
      },
      {
        num: "0603",
        name: "具有隔音效果的金属建筑材料",
      },
      {
        num: "0604",
        name: "金属轨道",
      },
      {
        num: "0607",
        name: "金属螺丝",
      },
      {
        num: "0608",
        name: "金属关门器（非电动）",
      },
      {
        num: "0610",
        name: "金属挂锁（非电子）",
      },
      {
        num: "0611",
        name: "保险箱（金属或非金属）",
      },
      {
        num: "0614",
        name: "金属车牌",
      },
    ],
    company: "小米科技有限责任公司",
    type_num: "6",
    agent: "-",
    steps: [],
    apply_date: "2023-05-30",
  },
  {
    reg_number: "66308461A",
    period: "2023年04月14日至2033年04月13日",
    id: "63b1b6fa288f83f1d0c95768",
    image_name:
      "https://qxb-img-osscache.qixin.com/official_trademark/625d31bf73122eab2873f0bef8a61d53.jpg",
    name: "图形",
    status: "注册商标",
    type_name: "灯具空调",
    products: [
      {
        num: "1107",
        name: "加热装置",
      },
    ],
    company: "苹果（中国）有限公司",
    type_num: "11",
    agent: "直接办理",
    steps: [
      {
        date: "2022-12-31",
        step: "商标注册申请-等待驳回复审",
      },
      {
        date: "2022-12-08",
        step: "商标注册申请-等待驳回通知发文",
      },
      {
        date: "2022-08-19",
        step: "商标注册申请-等待受理通知书发文",
      },
      {
        date: "2022-08-01",
        step: "商标注册申请-申请收文",
      },
      {
        date: "2023-05-18",
        step: "商标注册申请-等待注册证发文",
      },
    ],
    apply_date: "2022-08-01",
  },
  {
    reg_number: "66308464",
    period: "2023年01月28日至2033年01月27日",
    id: "62ea03cd290bd5b50cfc2f52",
    image_name:
      "https://qxb-img-osscache.qixin.com/official_trademark/7a14f246f6b48700916d6fa64032f3ba.jpg",
    name: "图形",
    status: "注册商标",
    type_name: "机械设备",
    products: [
      {
        num: "0723",
        name: "搅拌机",
      },
      {
        num: "0733",
        name: "搅拌机",
      },
      {
        num: "0709",
        name: "搅拌机",
      },
      {
        num: "0723",
        name: "洗碗机",
      },
      {
        num: "0723",
        name: "非手动磨咖啡机",
      },
      {
        num: "0724",
        name: "洗衣机",
      },
      {
        num: "0723",
        name: "家用电动打蛋器",
      },
      {
        num: "0723",
        name: "家用电动榨水果机",
      },
      {
        num: "0752",
        name: "清洁用吸尘装置",
      },
      {
        num: "0709",
        name: "电动意式面食制作机",
      },
      {
        num: "0709",
        name: "奶油机",
      },
      {
        num: "0723",
        name: "电动螺旋切菜机",
      },
      {
        num: "0742",
        name: "磨刀机",
      },
    ],
    company: "苹果（中国）有限公司",
    type_num: "7",
    agent: "直接办理",
    steps: [
      {
        date: "2022-08-01",
        step: "商标注册申请-申请收文",
      },
      {
        date: "2022-08-19",
        step: "商标注册申请-等待受理通知书发文",
      },
      {
        date: "2023-03-13",
        step: "商标注册申请-等待注册证发文",
      },
    ],
    apply_date: "2022-08-01",
  },
];
export default trademarks;
