import { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import { palette1 } from './palette';

function Chart16() {
  const element = useRef();
  useEffect(() => {
    const chart = new Chart(element.current, {
      type: 'pie',
      data: {
        labels: [ '影视作品', '文字作品', '图形视觉作品', '游戏计算机软件', '戏剧作品' ],
        datasets: [
          {
            data: [ 234, 123, 341, 1831, 877 ],
            backgroundColor: palette1
          }
        ]
      },
      options: {
        aspectRatio: 1.5,
        layout: {
          padding: 16
        },
        plugins: {
          legend: { position: 'right' }
        }
      }
    });
    return () => chart.destroy();
  }, []);
  return (
    <canvas ref={element} />
  );
}

export default Chart16;