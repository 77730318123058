import { Link } from 'react-router-dom';
import { Form, Input, Button, Divider } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import styles from "./recovery.module.css";

export default function Recovery() {
  return (
    <>
      <section className={styles.background}>
        <div className={styles.container}>
          <div className={styles.jumbo}>
            <div className={styles.login}>
              <div className={styles.title}>重设密码</div>
              <div className={styles.welcome}>
                <div>请输入新的登录密码</div>
              </div>
              <Form>
                <Form.Item name="username">
                  <Input size="large" prefix={<MailOutlined />} placeholder="邮箱" disabled />
                </Form.Item>
                <Divider>新的登录密码</Divider>
                <Form.Item name="password">
                  <Input.Password size="large" prefix={<LockOutlined />} placeholder="密码" />
                </Form.Item>
                <Form.Item name="repeat">
                  <Input.Password size="large" prefix={<LockOutlined />} placeholder="再次输入密码" />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" type="primary" size="large" block>提交</Button>
                </Form.Item>
                <div>
                  <Link to="/dfmg">返回并重新登录</Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}