const patents = [
  {
    creditNo: "91110108551385082Q",
    patents: [
      {
        patent_name: "手机壳",
        role_history: "0",
        role: "原始专利权人",
        app_person: "北京小米科技有限责任公司",
        request_date: "2021-03-23",
        type_name: "中国外观设计专利",
        id: "6df7b4e4e2b64bc1b7e6f91f5f456ab5",
        request_num: "CN202130158176.0",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "授权",
      },
      {
        patent_name: "净水器（YM-JS190706001）",
        role_history: "0",
        role: "专利权人",
        app_person: "佛山市云米电器科技有限公司;小米科技有限责任公司;陈小平",
        request_date: "2019-07-25",
        type_name: "中国外观设计专利",
        id: "844e8b42ca2c4fe3a865c141f398ab9f",
        request_num: "CN201930399918.1",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "专利权的终止",
      },
      {
        patent_name: "净水器（YM-JS190706001）",
        role_history: "0",
        role: "原始专利权人",
        app_person: "佛山市云米电器科技有限公司;小米科技有限责任公司;陈小平",
        request_date: "2019-07-25",
        type_name: "中国外观设计专利",
        id: "eb2ccbd56eb2425aa471c5331ba73b5b",
        request_num: "CN201930399918.1",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "专利权的终止",
      },
      {
        patent_name: "带显示界面的净水器",
        role_history: "0",
        role: "专利权人",
        app_person: "佛山市云米电器科技有限公司;小米科技有限责任公司;陈小平",
        request_date: "2016-06-12",
        type_name: "中国外观设计专利",
        id: "0b2b2b6bb5374130b60fc4d4e42ff7be",
        request_num: "CN201630236344.2",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "授权",
      },
      {
        patent_name: "带显示界面的净水器",
        role_history: "0",
        role: "原始专利权人",
        app_person: "佛山市云米电器科技有限公司;小米科技有限责任公司;陈小平",
        request_date: "2016-06-12",
        type_name: "中国外观设计专利",
        id: "61033ae8016c4479aef948377a5d165a",
        request_num: "CN201630236344.2",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "授权",
      },
      {
        patent_name: "带显示界面的净水器",
        role_history: "0",
        role: "原始申请人",
        app_person: "佛山市云米电器科技有限公司;小米科技有限责任公司;陈小平",
        request_date: "2016-06-12",
        type_name: "中国外观设计专利",
        id: "ba1f1f809303432fa7edbf9b8bd6eedb",
        request_num: "CN201630236344.2",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "授权",
      },
      {
        patent_name: "智能电热水壶",
        role_history: "0",
        role: "原始申请人",
        app_person: "佛山市云米电器科技有限公司;小米科技有限责任公司;陈小平",
        request_date: "2016-05-17",
        type_name: "中国实用新型专利",
        id: "d7dcbe6abd034344873a5a88197cde29",
        request_num: "CN201620448203.1",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "授权",
      },
    ],
  },
  {
    creditNo: "914403001922038216",
    patents: [
      {
        patent_name: "一种基于5G网络的信息安全鉴权方法及系统",
        role_history: "0",
        role: "专利权人",
        app_person:
          "国网浙江省电力有限公司信息通信分公司;国网浙江省电力有限公司杭州供电公司;华为技术有限公司;中国移动通信集团浙江有限公司",
        request_date: "2023-05-04",
        type_name: "中国发明专利",
        id: "c30d28c2214d436a9da0886c33ab268a",
        request_num: "CN202310490011.1",
        authorize_tag: "0",
        outhor_date: "2023-05-30",
        outhor_num: "CN116193432A",
        last_status: "公布",
      },
      {
        patent_name: "一种基于5G网络的信息安全鉴权方法及系统",
        role_history: "0",
        role: "原始申请人",
        app_person:
          "国网浙江省电力有限公司信息通信分公司;国网浙江省电力有限公司杭州供电公司;华为技术有限公司;中国移动通信集团浙江有限公司",
        request_date: "2023-05-04",
        type_name: "中国发明专利",
        id: "86eda525c4ee4260a987a71256a57d4c",
        request_num: "CN202310490011.1",
        authorize_tag: "0",
        outhor_date: "2023-05-30",
        outhor_num: "CN116193432A",
        last_status: "公布",
      },
      {
        patent_name: "一种物联网边缘环境下通感一体化智能传输与调控方法",
        role_history: "0",
        role: "专利权人",
        app_person: "南京邮电大学;东南大学;华为技术有限公司",
        request_date: "2023-03-07",
        type_name: "中国发明专利",
        id: "ebc0b8097fbe41d39d089ce0fa633d2a",
        request_num: "CN202310207125.0",
        authorize_tag: "0",
        outhor_date: "2023-05-05",
        outhor_num: "CN116073928A",
        last_status: "实质审查的生效",
      },
      {
        patent_name: "一种物联网边缘环境下通感一体化智能传输与调控方法",
        role_history: "0",
        role: "原始申请人",
        app_person: "南京邮电大学;东南大学;华为技术有限公司",
        request_date: "2023-03-07",
        type_name: "中国发明专利",
        id: "d4e84a07bc7a4c80ba44527e1e1480be",
        request_num: "CN202310207125.0",
        authorize_tag: "0",
        outhor_date: "2023-05-05",
        outhor_num: "CN116073928A",
        last_status: "实质审查的生效",
      },
      {
        patent_name: "一种基于动态优化的物联网边缘设备协同接入方法",
        role_history: "0",
        role: "专利权人",
        app_person: "南京邮电大学;华为技术有限公司;东南大学;盐城工学院",
        request_date: "2023-03-07",
        type_name: "中国发明专利",
        id: "71af21e5171c490ba15a572124f85256",
        request_num: "CN202310207123.1",
        authorize_tag: "0",
        outhor_date: "2023-05-05",
        outhor_num: "CN116074939A",
        last_status: "实质审查的生效",
      },
    ],
  },
  {
    creditNo: "91330201764518889H",
    patents: [
      {
        patent_name: "保健鞋底",
        role_history: "0",
        role: "专利权人",
        app_person: "苹果（中国）有限公司",
        request_date: "2014-12-31",
        type_name: "中国实用新型专利",
        id: "a21c30e121e040d68266fcddd8554503",
        request_num: "CN201420862911.0",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "授权",
      },
      {
        patent_name: "保健鞋底",
        role_history: "0",
        role: "原始专利权人",
        app_person: "苹果（中国）有限公司",
        request_date: "2014-12-31",
        type_name: "中国实用新型专利",
        id: "b235161799734f11ac4370464d108895",
        request_num: "CN201420862911.0",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "授权",
      },
      {
        patent_name: "保健鞋底",
        role_history: "0",
        role: "原始申请人",
        app_person: "苹果（中国）有限公司",
        request_date: "2014-12-31",
        type_name: "中国实用新型专利",
        id: "fbab33366c354d67b04e8ddd79ef76c2",
        request_num: "CN201420862911.0",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "授权",
      },
      {
        patent_name: "多边形金属扣",
        role_history: "0",
        role: "专利权人",
        app_person: "苹果(中国)有限公司",
        request_date: "2009-01-14",
        type_name: "中国实用新型专利",
        id: "9a5281aa149241029c215ceab34fefcb",
        request_num: "CN200920000871.8",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "专利权的终止",
      },
      {
        patent_name: "牛仔裤的后口袋",
        role_history: "0",
        role: "专利权人",
        app_person: "苹果(中国)有限公司",
        request_date: "2009-01-14",
        type_name: "中国实用新型专利",
        id: "0203e9b38fe4438c8829ba51aee04d6a",
        request_num: "CN200920000870.3",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "专利权的终止",
      },
      {
        patent_name: "多边形金属扣",
        role_history: "0",
        role: "原始专利权人",
        app_person: "苹果(中国)有限公司",
        request_date: "2009-01-14",
        type_name: "中国实用新型专利",
        id: "5c51167b67ff48438d7d24a0714c046b",
        request_num: "CN200920000871.8",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "专利权的终止",
      },
    ],
  },
  {
    creditNo: "91350500768588454G",
    patents: [
      {
        patent_name: "一种基于5G网络的信息安全鉴权方法及系统",
        role_history: "0",
        role: "原始申请人",
        app_person:
          "国网浙江省电力有限公司信息通信分公司;国网浙江省电力有限公司杭州供电公司;华为技术有限公司;中国移动通信集团浙江有限公司",
        request_date: "2023-05-04",
        type_name: "中国发明专利",
        id: "86eda525c4ee4260a987a71256a57d4c",
        request_num: "CN202310490011.1",
        authorize_tag: "0",
        outhor_date: "2023-05-30",
        outhor_num: "CN116193432A",
        last_status: "公布",
      },
      {
        patent_name: "一种物联网边缘环境下通感一体化智能传输与调控方法",
        role_history: "0",
        role: "专利权人",
        app_person: "南京邮电大学;东南大学;华为技术有限公司",
        request_date: "2023-03-07",
        type_name: "中国发明专利",
        id: "ebc0b8097fbe41d39d089ce0fa633d2a",
        request_num: "CN202310207125.0",
        authorize_tag: "0",
        outhor_date: "2023-05-05",
        outhor_num: "CN116073928A",
        last_status: "实质审查的生效",
      },
    ],
  },
  {
    creditNo: "91110108558521630L",
    patents: [
      {
        patent_name: "IDC检测方法和装置、IDC检测指示方法和装置",
        role_history: "0",
        role: "专利权人",
        app_person: "小米通讯技术有限公司",
        request_date: "2019-09-03",
        type_name: "中国发明专利",
        id: "ab4612c60d3c4b1daa76c0bf3a628750",
        request_num: "CN201980001884.3",
        authorize_tag: "0",
        outhor_date: "2020-01-31",
        outhor_num: "CN110741669A",
        last_status: "实质审查的生效",
      },
      {
        patent_name: "IDC检测方法和装置、IDC检测指示方法和装置",
        role_history: "0",
        role: "原始申请人",
        app_person: "小米通讯技术有限公司",
        request_date: "2019-09-03",
        type_name: "中国发明专利",
        id: "acef5974b9074c81a46ac1ade0d121c8",
        request_num: "CN201980001884.3",
        authorize_tag: "0",
        outhor_date: "2020-01-31",
        outhor_num: "CN110741669A",
        last_status: "实质审查的生效",
      },
      {
        patent_name: "竞争窗口长度确定方法和装置",
        role_history: "0",
        role: "专利权人",
        app_person: "小米通讯技术有限公司",
        request_date: "2019-08-19",
        type_name: "中国发明专利",
        id: "cc65df923c914f4985cea09327841f07",
        request_num: "CN201980001747.X",
        authorize_tag: "0",
        outhor_date: "2020-02-07",
        outhor_num: "CN110771250A",
        last_status: "实质审查的生效",
      },
      {
        patent_name: "竞争窗口长度确定方法和装置",
        role_history: "0",
        role: "原始申请人",
        app_person: "小米通讯技术有限公司",
        request_date: "2019-08-19",
        type_name: "中国发明专利",
        id: "d0cff51b5d6e42b4bcaa14660b1f9f4c",
        request_num: "CN201980001747.X",
        authorize_tag: "0",
        outhor_date: "2020-02-07",
        outhor_num: "CN110771250A",
        last_status: "实质审查的生效",
      },
      {
        patent_name: "确定参考时域单元的方法及装置",
        role_history: "0",
        role: "专利权人",
        app_person: "小米通讯技术有限公司",
        request_date: "2019-08-16",
        type_name: "中国发明专利",
        id: "bdc23cc827254fb08c82b6c773cd1753",
        request_num: "CN201980001705.6",
        authorize_tag: "0",
        outhor_date: "2020-01-21",
        outhor_num: "CN110720252A",
        last_status: "实质审查的生效",
      },
      {
        patent_name: "确定参考时域单元的方法及装置",
        role_history: "0",
        role: "原始申请人",
        app_person: "小米通讯技术有限公司",
        request_date: "2019-08-16",
        type_name: "中国发明专利",
        id: "e5e06249a6cd40af9e1bec59a0d636e4",
        request_num: "CN201980001705.6",
        authorize_tag: "0",
        outhor_date: "2020-01-21",
        outhor_num: "CN110720252A",
        last_status: "实质审查的生效",
      },
    ],
  },
  {
    creditNo: "914420006947806297",
    patents: [
      {
        patent_name: "一种基于5G网络的信息安全鉴权方法及系统",
        role_history: "0",
        role: "原始申请人",
        app_person:
          "国网浙江省电力有限公司信息通信分公司;国网浙江省电力有限公司杭州供电公司;华为技术有限公司;中国移动通信集团浙江有限公司",
        request_date: "2023-05-04",
        type_name: "中国发明专利",
        id: "86eda525c4ee4260a987a71256a57d4c",
        request_num: "CN202310490011.1",
        authorize_tag: "0",
        outhor_date: "2023-05-30",
        outhor_num: "CN116193432A",
        last_status: "公布",
      },
      {
        patent_name: "一种物联网边缘环境下通感一体化智能传输与调控方法",
        role_history: "0",
        role: "专利权人",
        app_person: "南京邮电大学;东南大学;华为技术有限公司",
        request_date: "2023-03-07",
        type_name: "中国发明专利",
        id: "ebc0b8097fbe41d39d089ce0fa633d2a",
        request_num: "CN202310207125.0",
        authorize_tag: "0",
        outhor_date: "2023-05-05",
        outhor_num: "CN116073928A",
        last_status: "实质审查的生效",
      },
    ],
  },
  {
    creditNo: "91110302587727910P",
    patents: [
      {
        patent_name: "一种色斑修补系统和方法",
        role_history: "0",
        role: "专利权人",
        app_person: "深圳市联测光电科技有限公司;北京小米电子产品有限公司",
        request_date: "2021-07-09",
        type_name: "中国发明专利",
        id: "b175a7744a724f40900aca5df0518f2d",
        request_num: "CN202110780635.8",
        authorize_tag: "0",
        outhor_date: "2021-10-22",
        outhor_num: "CN113539151A",
        last_status: "实质审查的生效",
      },
      {
        patent_name: "一种色斑修补系统和方法",
        role_history: "0",
        role: "原始申请人",
        app_person: "深圳市联测光电科技有限公司;北京小米电子产品有限公司",
        request_date: "2021-07-09",
        type_name: "中国发明专利",
        id: "32f6bad96952490b99e46d6366e8434c",
        request_num: "CN202110780635.8",
        authorize_tag: "0",
        outhor_date: "2021-10-22",
        outhor_num: "CN113539151A",
        last_status: "实质审查的生效",
      },
      {
        patent_name: "一种显示装置及其接口类型选择方法",
        role_history: "0",
        role: "专利权人",
        app_person: "咸阳彩虹光电科技有限公司;北京小米电子产品有限公司",
        request_date: "2019-09-18",
        type_name: "中国发明专利",
        id: "30f3df77c909432bbc3d09ef769488f4",
        request_num: "CN201910878920.6",
        authorize_tag: "0",
        outhor_date: "2021-03-19",
        outhor_num: "CN112530379A",
        last_status: "发明专利申请公布后的驳回",
      },
      {
        patent_name: "一种显示装置及其接口类型选择方法",
        role_history: "0",
        role: "原始申请人",
        app_person: "咸阳彩虹光电科技有限公司;北京小米电子产品有限公司",
        request_date: "2019-09-18",
        type_name: "中国发明专利",
        id: "e7bc14ed3c194b798e38bbf3aacc4b4c",
        request_num: "CN201910878920.6",
        authorize_tag: "0",
        outhor_date: "2021-03-19",
        outhor_num: "CN112530379A",
        last_status: "发明专利申请公布后的驳回",
      },
    ],
  },
  {
    creditNo: "91110400MA04ENG447",
    patents: [
      {
        patent_name: "排气阀",
        role_history: "0",
        role: "专利权人",
        app_person: "杭州小米环境科技有限公司",
        request_date: "2021-09-08",
        type_name: "中国实用新型专利",
        id: "528e5398b4cf40bdbcb758408f0629ac",
        request_num: "CN202122170358.7",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "授权",
      },
      {
        patent_name: "排气阀",
        role_history: "0",
        role: "原始申请人",
        app_person: "杭州小米环境科技有限公司",
        request_date: "2021-09-08",
        type_name: "中国实用新型专利",
        id: "44c7af7e32184d6998edf27f3e5bc44d",
        request_num: "CN202122170358.7",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "授权",
      },
    ],
  },
  {
    creditNo: "91330103580267747M",
    patents: [
      {
        patent_name: "风帽及排烟系统",
        role_history: "0",
        role: "原始专利权人",
        app_person: "杭州小米环境科技有限公司",
        request_date: "2022-01-28",
        type_name: "中国实用新型专利",
        id: "42b6d7e6d5a14fd489082fcc532805cc",
        request_num: "CN202220242887.5",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "授权",
      },
      {
        patent_name: "排气阀",
        role_history: "0",
        role: "原始申请人",
        app_person: "杭州小米环境科技有限公司",
        request_date: "2021-09-08",
        type_name: "中国实用新型专利",
        id: "372d215263a74b0688385cc3b9e44bb9",
        request_num: "CN202122170356.8",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "授权",
      },
      {
        patent_name: "排气阀",
        role_history: "0",
        role: "原始专利权人",
        app_person: "杭州小米环境科技有限公司",
        request_date: "2021-09-08",
        type_name: "中国实用新型专利",
        id: "67a2e667f206482db3bcc296827d4467",
        request_num: "CN202122170356.8",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "授权",
      },
      {
        patent_name: "排气阀",
        role_history: "0",
        role: "专利权人",
        app_person: "杭州小米环境科技有限公司",
        request_date: "2021-09-08",
        type_name: "中国实用新型专利",
        id: "528e5398b4cf40bdbcb758408f0629ac",
        request_num: "CN202122170358.7",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "授权",
      },
      {
        patent_name: "排气阀",
        role_history: "0",
        role: "原始申请人",
        app_person: "杭州小米环境科技有限公司",
        request_date: "2021-09-08",
        type_name: "中国实用新型专利",
        id: "44c7af7e32184d6998edf27f3e5bc44d",
        request_num: "CN202122170358.7",
        authorize_tag: "2",
        outhor_date: "",
        outhor_num: "",
        last_status: "授权",
      },
    ],
  },
];

export default patents;
