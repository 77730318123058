import { Form, Input, Button, Alert } from "antd";
import styles from "./index.module.css";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

export default function Index() {
  // const [status, setStatus] = useState(STATUS.idle);
  // const [error, setError] = useState(null);
  const navigate = useNavigate();
  const onFinish = (values) => {
    const doPost = async () => {
      // setStatus(STATUS.loading);
      let pathname = "/1/query/companies";
      // const { username, password } = values;

      // if (username === "admin" && password === "12345678") {
      // setStatus(STATUS.success);
      navigate(pathname, { replace: true });
      // } else {
      // setStatus(STATUS.failure);
      // setError("输入的账号或密码不正确");
      // }
    };

    doPost();
    console.log("values :>> ", values);
  };
  return (
    <>
      <section className={styles.background}>
        <div className={styles.container}>
          <div className={styles.jumbo}>
            <div className={styles.slogan}>
              <div className={styles.logo}>
                <img src="/logo-shzlcyy.png" alt="logo" />
              </div>
              <div className={styles.primary}>
                {/* <span className={styles.sub}>环球互通</span> */}
                <br />
                <span className={styles.strong}>园区品牌管理系统</span>
              </div>
              <div className={styles.secondary}>
                为园区品牌服务赋能，为企业提供高质量的品牌服务
              </div>
            </div>
            <div className={styles.login}>
              <div className={styles.title}>登录</div>
              <div className={styles.welcome}>
                { true ? <div>本系统由<Link to="https://www.biaoxq.com" target="_blank">环球互通</Link>提供技术支持</div> : <Alert message="用户名或密码错误，请重新登录" type="error" /> }
              </div>
              <Form onFinish={onFinish}>
                <Form.Item name="username">
                  <Input
                    size="large"
                    prefix={<MailOutlined />}
                    placeholder="邮箱"
                  />
                </Form.Item>
                <Form.Item name="password">
                  <Input.Password
                    size="large"
                    prefix={<LockOutlined />}
                    placeholder="密码"
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" type="primary" size="large" block>立即登录</Button>
                </Form.Item>
                <div>
                  <Link to="/dfmg/password-recovery">忘记了密码？</Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className={styles.container}>
          <div className={styles.title}>
            <div className={styles.sub}>系统功能介绍</div>
            <h2>园区品牌管理系统的“五项功能”</h2>
          </div>
          <div className={styles.cards}>
            <div className={styles.cardItem}>
              <div className={styles.thumb}>
                <img src="/icon/chart-mixed-regular.svg" alt="图标" />
              </div>
              <div className={styles.title}>数据统计</div>
              <div className={styles.description}>
                以最直观的方式，呈现园区入驻企业的各种品牌统计数据，为园区企业服务提供运营方向
              </div>
              <div className={styles.action}>
                <Button type="primary" size="large">
                  了解详情
                </Button>
              </div>
            </div>
            <div className={styles.cardItem}>
              <div className={styles.thumb}>
                <img
                  src="/icon/magnifying-glass-chart-regular.svg"
                  alt="图标"
                />
              </div>
              <div className={styles.title}>数据检索</div>
              <div className={styles.description}>
                掌握园区入驻企业的关键品牌信息，查阅企业的品牌经营状态，为招商决策注入更多信心
              </div>
              <div className={styles.action}>
                <Button type="primary" size="large">
                  了解详情
                </Button>
              </div>
            </div>
            <div className={styles.cardItem}>
              <div className={styles.thumb}>
                <img src="/icon/monitor-waveform-regular.svg" alt="图标" />
              </div>
              <div className={styles.title}>监测预警</div>
              <div className={styles.description}>
                全天候监测园区入驻企业的品牌状态，关注企业潜在品牌问题，树立园区品牌服务的专业形象
              </div>
              <div className={styles.action}>
                <Button type="primary" size="large">
                  了解详情
                </Button>
              </div>
            </div>
            <div className={styles.cardItem}>
              <div className={styles.thumb}>
                <img src="/icon/user-tie-hair-regular.svg" alt="图标" />
              </div>
              <div className={styles.title}>品牌赋能</div>
              <div className={styles.description}>
                提供五大类品牌服务功能，覆盖园区入驻企业的各类品牌需求，显著提升园区企业服务能力
              </div>
              <div className={styles.action}>
                <Button type="primary" size="large">
                  了解详情
                </Button>
              </div>
            </div>
            <div className={styles.cardItem}>
              <div className={styles.thumb}>
                <img src="/icon/comment-smile-regular.svg" alt="图标" />
              </div>
              <div className={styles.title}>咨询服务</div>
              <div className={styles.description}>
                搭建园企互动平台，即时了解园区入驻企业的品牌需求，成为园区企业服务工作的有力抓手
              </div>
              <div className={styles.action}>
                <Button type="primary" size="large">
                  了解详情
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className={styles.footer}>
        <Footer />
      </footer>
    </>
  );
}
