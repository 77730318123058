import { Row, Col, Card } from 'antd';
import PageHeader from '../../components/PageHeader';
import styles from './empowerment.module.css';
import { useNavigate } from 'react-router-dom';

export default function Index() {
  const navigate = useNavigate();
  const goto = id => navigate(`/dfmg/empowerment/${id}`);
  return (
    <>
      <PageHeader
        title="品牌赋能"
        description="赋能园区16项品牌服务能力，提升园区品牌服务水平。"
      />
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/知识产权.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('1')}>
            <Card.Meta title="知识产权" description={<div>提供高效、专业的知识产权服务<br />为您的知识产权保驾护航</div>} />
          </Card>
        </Col>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/品牌托管.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('2')}>
            <Card.Meta title="品牌托管" description={<div>最专业的品牌管家，为品牌发展保驾护航<br/>让您省时、省力又省心</div>} />
          </Card>
        </Col>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/品牌数据.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('3')}>
            <Card.Meta title="品牌数据" description={<div>洞悉品牌脉络，掌握品牌动态<br />挖掘品牌数据，支持品牌运营</div>} />
          </Card>
        </Col>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/品牌维权.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('4')}>
            <Card.Meta title="品牌维权" description={<div>品牌权益不容侵犯，专业团队为您守护<br/>科学制定维权策略，品牌发展安全无虞</div>} />
          </Card>
        </Col>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/品牌培训.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('5')}>
            <Card.Meta title="品牌培训" description={<div>助力企业品牌建设<br/>提升企业品牌竞争力</div>} />
          </Card>
        </Col>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/项目辅导.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('6')}>
            <Card.Meta title="项目辅导" description={<div>科学制定申报方案<br/>有效提升通过率</div>} />
          </Card>
        </Col>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/品牌商务.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('7')}>
            <Card.Meta title="品牌商务" description={<div>聚集品牌力量<br/>提升品牌价值</div>} />
          </Card>
        </Col>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/品牌交易.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('8')}>
            <Card.Meta title="品牌交易" description={<div>即买即用，比商标注册快270天<br/>买卖商标就找标星球</div>} />
          </Card>
        </Col>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/品牌授权.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('9')}>
            <Card.Meta title="品牌授权" description={<div>海量IP资源<br/>快速获取授权文件</div>} />
          </Card>
        </Col>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/品牌监测.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('9')}>
            <Card.Meta title="品牌监测" description={<div>实时了解品牌动态，及时预警品牌风险<br/>有效管理品牌行情，提升品牌竞争优势</div>} />
          </Card>
        </Col>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/环球视角.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('10')}>
            <Card.Meta title="环球视角" description={<div>汇聚行业政策，网罗行业动态<br/>赋能品牌发展</div>} />
          </Card>
        </Col>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/品牌查询.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('11')}>
            <Card.Meta title="品牌查询" description={<div>聚合全网数据<br/>品牌动态及时掌握</div>} />
          </Card>
        </Col>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/品牌规划.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('12')}>
            <Card.Meta title="品牌规划" description={<div>专业规划品牌成长之路<br/>树立企业发展指南针</div>} />
          </Card>
        </Col>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/环球品牌港.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('13')}>
            <Card.Meta title="环球品牌港" description={<div>为品牌提供专业化的展示平台<br/>引领品牌全面升级</div>} />
          </Card>
        </Col>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/品牌评估.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('14')}>
            <Card.Meta title="品牌评估" description={<div>专业、公正、有效力<br/>高效、权威、广泛认可</div>} />
          </Card>
        </Col>
        <Col span={6}>
          <Card hoverable cover={<img className={styles.cover} src="/cover/品牌金融.png" alt="封面图" />} actions={['查看详情']} onClick={() => goto('15')}>
            <Card.Meta title="品牌金融" description={<div>量化品牌价值<br/>架构金融桥梁</div>} />
          </Card>
        </Col>
      </Row>
    </>
  );
}