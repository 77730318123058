import { Button, Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { CaretDownOutlined, LogoutOutlined } from "@ant-design/icons";
import styles from "./Header.module.css";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  // const regex = /^(\/[\w-]+\/[\w-]+)\/?([\w-]+)*/;
  // const selectedKeys = reverse( drop( regex.exec(location.pathname) ) );
  // console.log(selectedKeys);

  const items = [
    {
      key: "/1/kanban",
      label: (
        <span>
          数据统计
          <CaretDownOutlined style={{ marginLeft: ".25rem" }} />
        </span>
      ),
      children: [
        { key: "/1/kanban/1", label: "企业数据统计" },
        { key: "/1/kanban/2", label: "商标数据统计" },
        { key: "/1/kanban/3", label: "专利数据统计" },
        { key: "/1/kanban/4", label: "著作权数据统计" },
      ],
    },
    {
      key: "/1/query",
      label: (
        <span>
          数据检索
          <CaretDownOutlined style={{ marginLeft: ".25rem" }} />
        </span>
      ),
      children: [
        { key: "/1/query/companies", label: "企业数据检索" },
        { key: "/1/query/trademarks", label: "商标数据检索" },
        { key: "/1/query/patents", label: "专利数据检索" },
        { key: "/1/query/copyrights", label: "著作权数据检索" },
      ],
    },
    { key: "/1/monitoring", label: "监测预警" },
    { key: "/1/empowerment", label: "品牌赋能" },
    { key: "/1/consulting", label: "咨询服务" },
  ];

  const flatten = (items) => {
    const flattened = [];
    items.forEach((item) => {
      flattened.push(item.key);
      if (item.children) {
        flattened.push(...flatten(item.children));
      }
    });
    return flattened;
  };

  const selectedKeys = flatten(items).filter((item) =>
    location.pathname.startsWith(item)
  );

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <img src="/logo.png" alt="logo" />
      </div>
      <Menu
        style={{ flex: 1 }}
        theme="dark"
        mode="horizontal"
        selectedKeys={selectedKeys}
        items={items}
        onClick={({ key }) => {
          navigate(key);
        }}
      />
      <Button
        type="primary"
        htmlType="submit"
        onClick={() => navigate("/1")}
        icon={<LogoutOutlined />}
      >
        退出登录
      </Button>
    </div>
  );
}
