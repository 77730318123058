import { Breadcrumb, Typography } from "antd";
import { isEmpty } from "lodash";
import styles from './PageHeader.module.css';

export default function PageHeader({ title, description, breadcrumb = [] }) {
  return (
    <div className={styles.root}>
      { !isEmpty(breadcrumb) && <Breadcrumb items={ breadcrumb } /> }
      <Typography>
        { title && <Typography.Title level={3}>{ title }</Typography.Title> }
        { description && <Typography.Paragraph type="secondary">{ description }</Typography.Paragraph> }
      </Typography>
    </div>
  );
}