import { Row, Col, Typography, Descriptions, Tag, Image, List } from "antd";
import { Link, useLocation } from "react-router-dom";
import { NumberOutlined } from "@ant-design/icons";
import PageHeader from "../../components/PageHeader";
import styles from "./query.module.css";
import trademarkInfo from "../../data.js/trademarkInfo";
import { useEffect, useState } from "react";
export default function Trademark() {
  const localtion = useLocation();
  const { state } = localtion;
  const [mockTrademarkInfo, setMockTrademarkInfo] = useState({});
  useEffect(() => {
    setMockTrademarkInfo(
      trademarkInfo.find((item) => {
        return item.reg_number === state;
      })
    );
  }, [state]);
  // console.log("state :>> ", state);
  // console.log("mockTrademarkInfo :>> ", mockTrademarkInfo);
  return (
    <>
      <PageHeader
        title="图形"
        description="展示商标的详细信息。"
        breadcrumb={[
          { title: <Link to="/1/query/trademarks">商标数据检索</Link> },
          { title: "图形" },
        ]}
      />
      <Row gutter={32}>
        <Col span={18}>
          <Typography.Title className={styles.title} id="基本信息" level={5}>
            <NumberOutlined />
            基本信息
          </Typography.Title>
          <Descriptions
            column={2}
            className={styles.descriptions}
            labelStyle={{ whiteSpace: "nowrap" }}
            bordered
          >
            <Descriptions.Item label="商标">
              <Image width={48} src={mockTrademarkInfo.org_image_url} />
            </Descriptions.Item>
            <Descriptions.Item label="商标名称">
              {mockTrademarkInfo.name}
            </Descriptions.Item>
            <Descriptions.Item label="分类">
              第{mockTrademarkInfo.type_num}类
            </Descriptions.Item>
            <Descriptions.Item label="申请号/注册号">
              {mockTrademarkInfo.reg_number}
            </Descriptions.Item>
            <Descriptions.Item label="商标类型">
              {mockTrademarkInfo.trademarktype}
            </Descriptions.Item>
            <Descriptions.Item label="商标状态">
              {mockTrademarkInfo.status}
            </Descriptions.Item>
            <Descriptions.Item label="申请时间">
              {mockTrademarkInfo.apply_date}
            </Descriptions.Item>
            <Descriptions.Item label="申请人">
              {mockTrademarkInfo.company}
            </Descriptions.Item>
            <Descriptions.Item label="申请人地址" span={2}>
              {mockTrademarkInfo.address_cn}
            </Descriptions.Item>
            <Descriptions.Item label="初审公告期号">
              {mockTrademarkInfo.first_pubno}
            </Descriptions.Item>
            <Descriptions.Item label="初审公告日期">
              {mockTrademarkInfo.first_pubdate}
            </Descriptions.Item>
            <Descriptions.Item label="注册公告期号">
              {mockTrademarkInfo.reg_pubno}
            </Descriptions.Item>
            <Descriptions.Item label="注册公告日期">
              {mockTrademarkInfo.reg_pubdate}
            </Descriptions.Item>
            <Descriptions.Item label="专用权期限" span={2}>
              {mockTrademarkInfo.period}
            </Descriptions.Item>
            <Descriptions.Item label="类似群组/商品/服务" span={2}>
              {mockTrademarkInfo?.products?.map((item, index) => {
                return (
                  <Tag key={index}>
                    {item.num}
                    {item.name}
                  </Tag>
                );
              })}
            </Descriptions.Item>
          </Descriptions>

          <Typography.Title className={styles.title} id="生命周期" level={5}>
            <NumberOutlined />
            生命周期
          </Typography.Title>
          <div className={styles.section}>
            <List
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta description={item.tiemstamp} />
                  <div>{item.title}</div>
                </List.Item>
              )}
              dataSource={[
                {
                  tiemstamp: "2021-08-27",
                  title: "商标申请",
                },
                {
                  tiemstamp: "2021-08-27",
                  title: "申请收文",
                },
                {
                  tiemstamp: "2021-09-15",
                  title: "受理通知书发文",
                },
                {
                  tiemstamp: "2021-11-13",
                  title: "初审公告",
                },
                {
                  tiemstamp: "2022-02-14",
                  title: "注册证发文",
                },
                {
                  tiemstamp: "2032-02-13",
                  title: "终止",
                },
              ]}
              rowKey="id"
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
