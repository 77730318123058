const relation = [
  {
    address: "北京市海淀区西二旗中路33号院6号楼6层006号",
    telephone: "010-69630728",
    regNo: "110108012660422",
    name: "小米科技有限责任公司",
    email: "chenchongwei@xiaomi.com",
    creditNo: "91110108551385082Q",
  },
  {
    address: "深圳市龙岗区坂田华为总部办公楼",
    telephone: "28780808",
    regNo: "440301103097413",
    name: "华为技术有限公司",
    email: "liulinjun@huawei.com",
    creditNo: "914403001922038216",
  },
  {
    address: "浙江省宁波杭州湾新区兴慈三路199号",
    telephone: "15257840955",
    regNo: "330200400073286",
    name: "苹果（中国）有限公司",
    email: "1312294163@qq.com",
    creditNo: "91330201764518889H",
  },
  {
    address: "晋江市梅岭街道双沟工业区",
    telephone: "13559555555",
    regNo: "350500400003093",
    name: "金苹果（中国）有限公司",
    email: "18350510037@qq.com",
    creditNo: "91350500768588454G",
  },
  {
    address: "北京市海淀区西二旗中路33号院6号楼9层019号",
    telephone: "010-69630728",
    regNo: "110000450147982",
    name: "小米通讯技术有限公司",
    email: "niuliren@xiaomi.com",
    creditNo: "91110108558521630L",
  },
  {
    address: "中山市南头镇南头大道东49号对面",
    telephone: "15113321688",
    regNo: "442000000270608",
    name: "中山市西沐电器有限公司",
    email: "111175814@qq.com",
    creditNo: "914420006947806297",
  },
  {
    address: "北京市北京经济技术开发区科创十街15号院5号楼8层802室",
    telephone: "010-69630728",
    regNo: "110000450195272",
    name: "北京小米电子产品有限公司",
    email: "chenchongwei@xiaomi.com",
    creditNo: "91110302587727910P",
  },
  {
    address: "北京市北京经济技术开发区科创十街15号院5号楼8层816室",
    telephone: "010-69630728",
    regNo: "110400031798725",
    name: "小米汽车有限公司",
    email: "chenchongwei@xiaomi.com",
    creditNo: "91110400MA04ENG447",
  },
  {
    address: "杭州市下城区费家塘路588号4幢412室",
    telephone: "15824472251",
    regNo: "330106000188633",
    name: "杭州小米环境科技有限公司",
    email: "547069071@qq.com",
    creditNo: "91330103580267747M",
  },
];
export default relation;
