import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import styles from './root.module.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Root() {
  return (
    <Layout>
      <Layout.Header className={styles.header}>
        <Header />
      </Layout.Header>
      <Layout.Content className={styles.body}>
        <Outlet />        
      </Layout.Content>
      <Layout.Footer>
        <Footer />
      </Layout.Footer>
    </Layout>
  );
}