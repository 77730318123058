import { Row, Col, Typography, Descriptions, Anchor, Image, List } from "antd";
import { Link, useLocation } from "react-router-dom";
import { NumberOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import styles from "./query.module.css";
import patentInfo from "../../data.js/patentInfo";
export default function Patent() {
  const localtion = useLocation();
  const { state } = localtion;
  const [mockPatentInfo, setMockPatentInfo] = useState({});
  useEffect(() => {
    setMockPatentInfo(
      patentInfo.find((item) => {
        return item.request_num === state.request_num;
        // return item.request_num === "CN202130158176.0";
      })
    );
  }, [state]);
  // console.log("mockPatentInfo :>> ", mockPatentInfo);
  return (
    <>
      <PageHeader
        title={mockPatentInfo.patent_name}
        description="展示专利的详细信息。"
        breadcrumb={[
          { title: <Link to="/1/query/patents">专利数据检索</Link> },
          { title: `${mockPatentInfo.patent_name}` },
        ]}
      />
      <Row gutter={32}>
        <Col span={18}>
          <Typography.Title className={styles.title} id="基本信息" level={5}>
            <NumberOutlined />
            基本信息
          </Typography.Title>
          <Descriptions
            column={2}
            className={styles.descriptions}
            labelStyle={{ whiteSpace: "nowrap" }}
            bordered
          >
            <Descriptions.Item label="名称" span={2}>
              {mockPatentInfo.patent_name}
            </Descriptions.Item>
            <Descriptions.Item label="类型">
              {state.type_name}
            </Descriptions.Item>
            <Descriptions.Item label="法律状态">
              {state.last_status}
            </Descriptions.Item>
            <Descriptions.Item label="申请日期">
              {state.request_date}
            </Descriptions.Item>
            <Descriptions.Item label="申请号">
              {state.request_num}
            </Descriptions.Item>
            <Descriptions.Item label="公布公告号"></Descriptions.Item>
            <Descriptions.Item label="公布公告时间">
              {mockPatentInfo.outhor_date}
            </Descriptions.Item>
            <Descriptions.Item label="授权公告号">-</Descriptions.Item>
            <Descriptions.Item label="授权公告时间">-</Descriptions.Item>
            <Descriptions.Item label="申请（专利权）人" span={2}>
              {state.app_person}
            </Descriptions.Item>
            <Descriptions.Item label="发明人 / 设计人" span={2}>
              {mockPatentInfo.designer}
            </Descriptions.Item>
            <Descriptions.Item label="IPC/LOC分类" span={2}>
              <ul style={{ paddingLeft: "1rem" }}>
                <li>
                  H04N21/234
                  视频基本流的处理，如视频流的拼接或者MPEG-4场景图操作
                </li>
                <li>
                  H04N21/235
                  附加数据的处理，如加扰附加数据或者处理内容描述符（2011.01）
                </li>
                <li>
                  H04N21/44
                  视频基本流的处理，例如：将从本地存储器获得的视频剪辑与输入视频流进行拼接，或者按照MPEG-4场景图呈现场景（2011.01）
                </li>
                <li>
                  H04N21/435
                  附加数据的处理，例如：附加数据的解密，或者从传输流提取的模块重构软件（2011.01）
                </li>
                <li>H04N21/81 其单媒体部件（2011.01）</li>
              </ul>
            </Descriptions.Item>
          </Descriptions>

          <Typography.Title className={styles.title} id="摘要" level={5}>
            <NumberOutlined />
            摘要
          </Typography.Title>
          <div className={styles.section}>
            <Typography>
              <Typography.Paragraph>
                本申请公开了视频生成方法和装置，涉及视频处理技术领域。该方法的一具体实施方式包括响应于获取到视频生成请求，基于视频生成请求，获取视频元素信息；基于新的动画描述文件和动画模板文件，得到目标动画；基于目标动画，执行截图操作，得到有序图片序列；基于有序图片序列，生成目标视频。该实施方式丰富了生成的视频样式，降低了开发成本。
              </Typography.Paragraph>
            </Typography>
          </div>

          <Typography.Title className={styles.title} id="附图" level={5}>
            <NumberOutlined />
            附图
          </Typography.Title>
          <div className={styles.section}>
            <Image
              width={256}
              src="//qxb-img-osscache.qixin.com/patents_pic/e3eb8fbe1a87ca88ba146bd0d86465a4.jpg"
            />
          </div>

          <Typography.Title className={styles.title} id="生命周期" level={5}>
            <NumberOutlined />
            生命周期
          </Typography.Title>
          <div className={styles.section}>
            <List
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta description={item.tiemstamp} />
                  <div>{item.title}</div>
                </List.Item>
              )}
              dataSource={[
                {
                  tiemstamp: "2023-03-24",
                  title: "申请",
                },
                {
                  tiemstamp: "2023-06-09",
                  title: "公布",
                },
                {
                  tiemstamp: "-",
                  title: "授权",
                },
                {
                  tiemstamp: "-",
                  title: "届满",
                },
              ]}
            />
          </div>
        </Col>
        <Col span={6}>
          <Anchor
            offsetTop={96}
            items={[
              { key: "1", href: "#基本信息", title: "基本信息" },
              { key: "2", href: "#摘要", title: "摘要" },
              { key: "3", href: "#附图", title: "附图" },
              { key: "4", href: "#生命周期", title: "生命周期" },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}
