const allpatents = [
  {
    patent_name: "手机壳",
    role_history: "0",
    role: "原始专利权人",
    app_person: "北京小米科技有限责任公司",
    request_date: "2021-03-23",
    type_name: "中国外观设计专利",
    id: "6df7b4e4e2b64bc1b7e6f91f5f456ab5",
    request_num: "CN202130158176.0",
    authorize_tag: "2",
    outhor_date: "",
    outhor_num: "",
    last_status: "授权",
  },

  {
    patent_name: "IDC检测方法和装置、IDC检测指示方法和装置",
    role_history: "0",
    role: "专利权人",
    app_person: "小米通讯技术有限公司",
    request_date: "2019-09-03",
    type_name: "中国发明专利",
    id: "ab4612c60d3c4b1daa76c0bf3a628750",
    request_num: "CN201980001884.3",
    authorize_tag: "0",
    outhor_date: "2020-01-31",
    outhor_num: "CN110741669A",
    last_status: "实质审查的生效",
  },
  {
    patent_name: "带显示界面的净水器",
    role_history: "0",
    role: "专利权人",
    app_person: "佛山市云米电器科技有限公司;小米科技有限责任公司;陈小平",
    request_date: "2016-06-12",
    type_name: "中国外观设计专利",
    id: "0b2b2b6bb5374130b60fc4d4e42ff7be",
    request_num: "CN201630236344.2",
    authorize_tag: "2",
    outhor_date: "",
    outhor_num: "",
    last_status: "授权",
  },
  {
    patent_name: "一种基于5G网络的信息安全鉴权方法及系统",
    role_history: "0",
    role: "原始申请人",
    app_person:
      "国网浙江省电力有限公司信息通信分公司;国网浙江省电力有限公司杭州供电公司;华为技术有限公司;中国移动通信集团浙江有限公司",
    request_date: "2023-05-04",
    type_name: "中国发明专利",
    id: "86eda525c4ee4260a987a71256a57d4c",
    request_num: "CN202310490011.1",
    authorize_tag: "0",
    outhor_date: "2023-05-30",
    outhor_num: "CN116193432A",
    last_status: "公布",
  },
  {
    patent_name: "带显示界面的净水器",
    role_history: "0",
    role: "原始申请人",
    app_person: "佛山市云米电器科技有限公司;小米科技有限责任公司;陈小平",
    request_date: "2016-06-12",
    type_name: "中国外观设计专利",
    id: "ba1f1f809303432fa7edbf9b8bd6eedb",
    request_num: "CN201630236344.2",
    authorize_tag: "2",
    outhor_date: "",
    outhor_num: "",
    last_status: "授权",
  },
  {
    patent_name: "一种物联网边缘环境下通感一体化智能传输与调控方法",
    role_history: "0",
    role: "专利权人",
    app_person: "南京邮电大学;东南大学;华为技术有限公司",
    request_date: "2023-03-07",
    type_name: "中国发明专利",
    id: "ebc0b8097fbe41d39d089ce0fa633d2a",
    request_num: "CN202310207125.0",
    authorize_tag: "0",
    outhor_date: "2023-05-05",
    outhor_num: "CN116073928A",
    last_status: "实质审查的生效",
  },
];
export default allpatents;
