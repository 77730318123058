import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale/zh_CN';
import theme from './theme';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Index from './pages';
import Root from './pages/root';
import Recovery from './pages/recovery';
import { Kanban1, Kanban2, Kanban3, Kanban4 } from './pages/kanban';
import { QueryCompanies, QueryTrademarks, QueryPatents, QueryCopyrights, QueryCompany, QueryTrademark, QueryPatent } from './pages/query';
import Monitoring from './pages/monitoring';
import MonitroingDetails from './pages/monitoring/details';
import Empowerment from './pages/empowerment';
import EmpowermentDetails from './pages/empowerment/details';
import Consulting from './pages/consulting';
import ConsultingDetails from './pages/consulting/details';

function App() {
  return (
    <ConfigProvider locale={zh_CN} theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/dfmg" />} />
          <Route path="/:pid" element={<Index />} />
          <Route path="/:pid/password-recovery" element={<Recovery />} />
          <Route path="/:pid" element={<Root />}>
            <Route path="kanban/1" element={<Kanban1 />} />
            <Route path="kanban/2" element={<Kanban2 />} />
            <Route path="kanban/3" element={<Kanban3 />} />
            <Route path="kanban/4" element={<Kanban4 />} />
            <Route path="query/companies" element={<QueryCompanies />} />
            <Route path="query/companies/:id" element={<QueryCompany />} />
            <Route path="query/trademarks" element={<QueryTrademarks />} />
            <Route path="query/trademarks/:id" element={<QueryTrademark />} />
            <Route path="query/patents" element={<QueryPatents />} />
            <Route path="query/patents/:id" element={<QueryPatent />} />
            <Route path="query/copyrights" element={<QueryCopyrights />} />
            <Route path="monitoring/:id" element={<MonitroingDetails />} />
            <Route path="monitoring" element={<Monitoring />} />
            <Route path="consulting/:id" element={<ConsultingDetails />} />
            <Route path="consulting" element={<Consulting />} />
            <Route path="empowerment/:id" element={<EmpowermentDetails />} />
            <Route path="empowerment" element={<Empowerment />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;