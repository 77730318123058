import { Link } from 'react-router-dom';
import { Form, Input, Modal, Alert, Row, Col } from "antd";
import styles from './Create.Modal.module.css';

export default function CreateModal({ open, onOk, onCancel }) {
  return (
    <Modal
      title="咨询服务申请表"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      width={720}
    >
      <Row gutter={32} className={styles.root}>
        <Col span={16}>
          <Alert className={styles.alert} type="info" message="请填写联系方式，咨询顾问将很快与其联系。" showIcon />
          <Form wrapperCol={{ span: 19 }} labelCol={{ span: 5 }}>
            <Form.Item label="咨询服务">
              <div className={styles.card}>
                <div className={styles.header}>
                  <img src="/cover/知识产权.png" alt="封面图" />
                </div>
                <div className={styles.body}>
                  <div className={styles.secondary}>服务代码：202300010001</div>
                  <div className={styles.primary}>知识产权</div>
                </div>
              </div>
            </Form.Item>
            <Form.Item label="公司" name="company">
              <Input placeholder="" />
            </Form.Item>
            <Form.Item label="联系人" name="name">
              <Input placeholder="" />
            </Form.Item>
            <Form.Item label="手机" name="mobile">
              <Input placeholder="" />
            </Form.Item>
            <Form.Item label="职务" name="jobTitle">
              <Input placeholder="" />
            </Form.Item>
            <Form.Item label="留言" name="memo">
              <Input.TextArea placeholder="" rows={6} />
            </Form.Item>
          </Form>
        </Col>
        <Col span={8}>
          <div className={styles.consultant}>
            <div className={styles.header}>
              <img src="/consultant/雷先生.jpg" alt="顾问头像" />
            </div>
            <div className={styles.body}>
              <div className={styles.row}>
                <div className={styles.secondary}>高级咨询服务顾问</div>
                <div className={styles.primary}>雷先生</div>
              </div>
              <div className={styles.row}>
                <div className={styles.secondary}>联系电话</div>
                <div className={styles.primary}><Link href="tel:+8618600000000">186-0000-0000</Link></div>
              </div>
              <div className={styles.row}>
                <div className={styles.secondary}>企业微信</div>
                <div className={styles.primary}>
                  <img className={styles.qrcode} src="/consultant/qr-雷先生.jpg" alt="二维码" />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  );
}