import { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import { palette1 } from './palette';

function Chart13() {
  const element = useRef();
  useEffect(() => {
    
    const chart = new Chart(element.current, {
      type: 'pie',
      data: {
        labels: ['外观', '实用新型', '发明'],
        datasets: [
          {
            data: [ 0, 2, 0 ],
            backgroundColor: palette1
          }
        ]
      },
      options: {
        aspectRatio: 1.5,
        layout: {
          padding: 16
        },
        plugins: {
          legend: { position: 'right' }
        }
      }
    });
    return () => chart.destroy();
  }, []);
  return (
    <canvas ref={element} />
  );
}

export default Chart13;