import {
  Button,
  Row,
  Col,
  Anchor,
  Typography,
  Descriptions,
  Tag,
  Table,
  Statistic,
  Image,
} from "antd";
import { Link, useLocation } from "react-router-dom";
import { NumberOutlined, PlusOutlined } from "@ant-design/icons";
import PageHeader from "../../components/PageHeader";
import styles from "./monitoring.module.css";
import { useEffect, useState } from "react";

import company from "../../data.js/company";
import relation from "../../data.js/relation";
// import risks from "../../data.js/risks";
import industries from "../../data.js/industries";

export default function Details() {
  const location = useLocation();
  const { state } = location;
  const [mockCompany, setMockCompany] = useState({});
  const [mockRelation, setMockRelation] = useState({});
  const [mockIndustries, setMockIndustries] = useState({});
  const [risks, setRisks] = useState([]);

  const generateDate = () => {
    const year = 2000 + Math.floor(Math.random() * 23);
    const month = Math.floor(Math.random() * 12) + 1; // 1到12之间的随机月份
    const day = Math.floor(Math.random() * 28) + 1; // 1到28之间的随机日期

    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    const generateRisks = (num) => {
      const risks = [];
      const usedDates = new Set();
  
      for (let i = 1; i <= num; i++) {
        let createdAt;
        do {
          createdAt = generateDate();
        } while (usedDates.has(createdAt));
        usedDates.add(createdAt);
  
        const risk = {
          id: i,
          createdAt: createdAt,
          category: "新增开庭公告",
          level: Math.floor(Math.random() * 2),
          description:
            "新增一则身份为被告的开庭公告，案由为买卖合同纠纷，将于2023-07-06开庭",
        };
  
        risks.push(risk);
      }
  
      return risks;
    };

    setMockCompany(
      company.find((item) => {
        return item.creditNo === state.sn;
      })
    );
    setMockRelation(
      relation.find((item) => {
        return item.creditNo === state.sn;
      })
    );
    setMockIndustries(
      industries.find((item) => {
        return item.creditNo === state.sn;
      })
    );
    setRisks(generateRisks(state.risks));
  }, [state]);
  return (
    <>
      <PageHeader
        title={mockCompany.name}
        description="实时监测企业的知识产权和经营状态，及时发现风险并作出提醒。"
        breadcrumb={[
          { title: <Link to="/1/monitoring">监测预警</Link> },
          { title: `${mockCompany.name}` },
        ]}
      />
      <div>
        <Tag color="#2db7f5">重点企业</Tag>
        <Button type="link" icon={<PlusOutlined />}>
          添加标签
        </Button>
      </div>
      <Row gutter={32}>
        <Col span={18}>
          <div className={styles.section}>
            <div className={styles.statistic}>
              <div className={styles.item}>
                <Statistic
                  title="经营风险"
                  value={state.risks}
                  valueStyle={{ color: "#EC5B56" }}
                />
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic
                  title="商标风险"
                  value={state.trademarkRisks}
                  valueStyle={{ color: "#EC5B56" }}
                />
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic
                  title="专利风险"
                  value={state.patentRisks}
                  valueStyle={{ color: "#EC5B56" }}
                />
              </div>
            </div>
          </div>

          <Typography.Title className={styles.title} id="基本信息" level={5}>
            <NumberOutlined />
            基本信息
          </Typography.Title>
          <Descriptions
            column={2}
            className={styles.descriptions}
            labelStyle={{ whiteSpace: "nowrap" }}
            bordered
          >
            <Descriptions.Item label="公司名称">
              {mockCompany.name}
            </Descriptions.Item>
            <Descriptions.Item label="法定代表人">
              {mockCompany.operName}
            </Descriptions.Item>
            <Descriptions.Item label="统一社会信用代码">
              {mockCompany.creditNo}
            </Descriptions.Item>
            <Descriptions.Item label="经营状态">
              <Tag color="#1B9C85">{mockCompany.new_status}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="所属行业">
              {mockIndustries.industry_name}
            </Descriptions.Item>
            <Descriptions.Item label="成立日期">
              {mockCompany.startDate}
            </Descriptions.Item>
            <Descriptions.Item label="企业类型">
              {mockCompany.econKind}
            </Descriptions.Item>
            <Descriptions.Item label="营业期限"></Descriptions.Item>
          </Descriptions>

          <Typography.Title className={styles.title} id="联系方式" level={5}>
            <NumberOutlined />
            联系方式
          </Typography.Title>
          <Descriptions
            column={2}
            className={styles.descriptions}
            labelStyle={{ whiteSpace: "nowrap" }}
            bordered
          >
            <Descriptions.Item label="联系电话">
              {mockRelation.telephone}
            </Descriptions.Item>
            <Descriptions.Item label="邮箱">
              {mockRelation.email}
            </Descriptions.Item>
            <Descriptions.Item label="地址" span={2}>
              {mockRelation.address}
            </Descriptions.Item>
          </Descriptions>

          <Typography.Title className={styles.title} id="经营风险" level={5}>
            <NumberOutlined />
            经营风险
          </Typography.Title>
          <div className={styles.section}>
            <Table
              columns={[
                {
                  title: "时间",
                  dataIndex: "createdAt",
                },
                {
                  title: "类型",
                  dataIndex: "category",
                },
                {
                  title: "等级",
                  dataIndex: "level",
                  render: (data) =>
                    data === 0 ? (
                      <Tag color="green">低风险</Tag>
                    ) : (
                      <Tag color="red">高风险</Tag>
                    ),
                },
                {
                  title: "描述",
                  dataIndex: "description",
                },
              ]}
              dataSource={risks}
              rowKey="id"
            />
          </div>

          <Typography.Title className={styles.title} id="商标风险" level={5}>
            <NumberOutlined />
            商标风险
          </Typography.Title>
          <div className={styles.section}>
            <Table
              columns={[
                {
                  title: "时间",
                  dataIndex: "createdAt",
                },
                {
                  title: "风险",
                  dataIndex: "risks",
                  render: (data) => (
                    <Typography.Text type="danger">{data}</Typography.Text>
                  ),
                },
                {
                  title: "商标",
                  dataIndex: "image",
                  render: (data) => <Image width={64} src={data} alt="商标" />,
                },
                {
                  title: "商标名称",
                  dataIndex: "title",
                },
                {
                  title: "注册号",
                  dataIndex: "sn",
                },
                {
                  title: "分类",
                  dataIndex: "category",
                },
              ]}
              dataSource={[
                {
                  createdAt: "2023年05月31",
                  risks: "临近商标专用期期限，待续展",
                  image:
                    "//brandimg.sudoyu.com/data/color/21/6ffc6e330c910a2112c1446dadb219b5.png",
                  title: "好家叮",
                  sn: "71942357",
                  category: "第21类",
                },
                {
                  createdAt: "2023年6月15日",
                  risks: "临近商标专用期期限，待续展",
                  image:
                    "https://qxb-img-osscache.qixin.com/official_trademark/bec2bb85e6bc266ad9ba7b483a4b3135.jpg",

                  title: "未家",
                  sn: "71943557",
                  category: "第9类",
                },
                {
                  createdAt: "2023年6月15日",
                  risks: "临近商标专用期期限，待续展",
                  image:
                    "https://qxb-img-osscache.qixin.com/official_trademark/e4134825fa7b6fc21a194950557e9413.jpg",

                  title: "服装鞋帽",
                  sn: "71908473",
                  category: "第25类",
                },
                {
                  createdAt: "2023年6月15日",
                  risks: "临近商标专用期期限，待续展",
                  image:
                    "https://qxb-img-osscache.qixin.com/official_trademark/719d1b116525f07e3c05ad0b371d184e.jpg",

                  title: "金属材料",
                  sn: "71915546",
                  category: "第6类",
                },
              ]}
              rowKey="sn"
            />
          </div>

          <Typography.Title className={styles.title} id="专利风险" level={5}>
            <NumberOutlined />
            专利风险
          </Typography.Title>
          <div className={styles.section}>
            <Table
              columns={[
                {
                  title: "时间",
                  dataIndex: "request_date",
                },
                {
                  title: "风险",
                  dataIndex: "risks",
                  render: (data) => (
                    <Typography.Text type="danger">{data}</Typography.Text>
                  ),
                },
                {
                  title: "专利名称",
                  dataIndex: "patent_name",
                },
                {
                  title: "申请号",
                  dataIndex: "request_num",
                },
                {
                  title: "类型",
                  dataIndex: "type_name",
                },
              ]}
              dataSource={[
                // {
                //   id: "1",
                //   createdAt: "2023年6月15日",
                //   risks: "临近商标专用期期限，待续展",
                //   title: "一种新原阿片碱型生物碱及其制备方法和应用",
                //   sn: "2019103847830",
                //   category: "发明",
                // },
                {
                  patent_name: "手机壳",
                  risks: "待缴年费",
                  request_date: "2021-03-23",
                  type_name: "中国外观设计专利",
                  id: "6df7b4e4e2b64bc1b7e6f91f5f456ab5",
                  request_num: "CN202130158176.0",
                },
                {
                  patent_name: "净水器（YM-JS190706001）",
                  risks: "待缴年费",
                  request_date: "2019-07-25",
                  type_name: "中国外观设计专利",
                  id: "844e8b42ca2c4fe3a865c141f398ab9f",
                  request_num: "CN201930399918.1",
                },
                {
                  patent_name: "净水器（YM-JS190706001）",
                  risks: "待缴年费",
                  request_date: "2019-07-25",
                  type_name: "中国外观设计专利",
                  id: "eb2ccbd56eb2425aa471c5331ba73b5b",
                  request_num: "CN201930399918.1",
                },
                {
                  patent_name: "带显示界面的净水器",
                  risks: "待缴年费",
                  request_date: "2016-06-12",
                  type_name: "中国外观设计专利",
                  id: "0b2b2b6bb5374130b60fc4d4e42ff7be",
                  request_num: "CN201630236344.2",
                },
                {
                  patent_name: "带显示界面的净水器",
                  risks: "待缴年费",
                  request_date: "2016-06-12",
                  type_name: "中国外观设计专利",
                  id: "61033ae8016c4479aef948377a5d165a",
                  request_num: "CN201630236344.2",
                },
                {
                  patent_name: "带显示界面的净水器",
                  risks: "待缴年费",
                  request_date: "2016-06-12",
                  type_name: "中国外观设计专利",
                  id: "ba1f1f809303432fa7edbf9b8bd6eedb",
                  request_num: "CN201630236344.2",
                },
                {
                  patent_name: "智能电热水壶",
                  risks: "待缴年费",
                  request_date: "2016-05-17",
                  type_name: "中国实用新型专利",
                  id: "d7dcbe6abd034344873a5a88197cde29",
                  request_num: "CN201620448203.1",
                },
              ]}
              rowKey="id"
            />
          </div>
        </Col>
        <Col span={6}>
          <Anchor
            offsetTop={96}
            items={[
              { key: "1", href: "#基本信息", title: "基本信息" },
              { key: "2", href: "#联系方式", title: "联系方式" },
              { key: "3", href: "#经营风险", title: "经营风险" },
              { key: "4", href: "#商标风险", title: "商标风险" },
              { key: "5", href: "#专利风险", title: "专利风险" },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}
