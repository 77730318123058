const theme = {
  token: {
    colorPrimary: '#1B9C85',
    colorLink: '#1B9C85',
    colorLinkHover: '#60C7AE',
    colorLinkActive: '#1B9C85',
  },
  components: {
    Menu: {
      horizontalItemHoverBg: '#60C7AE',
    },
  },
};

export default theme;