const copyrights = [
  {
    creditNo: "91110108551385082Q",
    copyrights: [
      {
        type_num: "-",
        related_companies: [],
        id: "643227b756e314cc8f4524d3",
        first_date: "-",
        name: "小米环形冷泵（英文名称：Mi IceLoop）",
        created_time: "2023-04-09",
        version: "-",
        type_name: "美术",
        approval_date: "2023-03-01",
        short_name: "-",
        company: "小米科技有限责任公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2023-F-00039251",
        success_date: "2022-11-02",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "63e4e2759b5e8af84134e500",
        first_date: "2022-08-26",
        name: "家人服务logo",
        created_time: "2023-02-09",
        version: "-",
        type_name: "美术",
        approval_date: "2023-01-19",
        short_name: "-",
        company: "小米科技有限责任公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2023-F-00014684",
        success_date: "2022-07-15",
      },

      {
        type_num: "-",
        related_companies: [],
        id: "61c0b432309939d4de59fe85",
        first_date: "-",
        name: "磕物",
        created_time: "2021-12-21",
        version: "-",
        type_name: "美术",
        approval_date: "2021-11-16",
        short_name: "-",
        company: "小米科技有限责任公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2021-F-00263588",
        success_date: "2020-08-05",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "6196691de94893f7d4454340",
        first_date: "-",
        name: "GAMIOCENE LOGO",
        created_time: "2021-11-18",
        version: "-",
        type_name: "美术",
        approval_date: "2021-10-26",
        short_name: "-",
        company: "小米科技有限责任公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2021-F-00245825",
        success_date: "2021-07-22",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "60ba764a777b71b4d7bb5efa",
        first_date: "-",
        name: "小米品牌标识",
        created_time: "2021-06-05",
        version: "-",
        type_name: "美术",
        approval_date: "2021-05-24",
        short_name: "-",
        company: "小米科技有限责任公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2021-F-00112980",
        success_date: "2021-02-24",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "60ba76393c089dfce5786d34",
        first_date: "-",
        name: "xiaomi字母Logo",
        created_time: "2021-06-05",
        version: "-",
        type_name: "美术",
        approval_date: "2021-05-24",
        short_name: "-",
        company: "小米科技有限责任公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2021-F-00112979",
        success_date: "2021-03-05",
      },
    ],
  },
  {
    creditNo: "914403001922038216",
    copyrights: [
      {
        type_num: "-",
        related_companies: [],
        id: "6374520836559c1464e3ffa8",
        first_date: "2022-10-13",
        name: "华为云网融合认证框架",
        created_time: "2022-11-16",
        version: "-",
        type_name: "其他",
        approval_date: "2022-11-09",
        short_name: "-",
        company: "华为技术有限公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2022-L-10232781",
        success_date: "2022-10-13",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "6374520836559c1464e3ffa7",
        first_date: "2022-10-13",
        name: "华为5G认证框架",
        created_time: "2022-11-16",
        version: "-",
        type_name: "其他",
        approval_date: "2022-11-09",
        short_name: "-",
        company: "华为技术有限公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2022-L-10232784",
        success_date: "2022-10-13",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "6374520836559c1464e3ffa6",
        first_date: "2022-10-13",
        name: "华为5G认证培养框架",
        created_time: "2022-11-16",
        version: "-",
        type_name: "其他",
        approval_date: "2022-11-09",
        short_name: "-",
        company: "华为技术有限公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2022-L-10232783",
        success_date: "2022-10-13",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "6374520736559c1464e3ffa5",
        first_date: "2022-10-13",
        name: "华为管理培训：数字化领导力关键要素和点线面体培训进阶方案",
        created_time: "2022-11-16",
        version: "-",
        type_name: "其他",
        approval_date: "2022-11-09",
        short_name: "-",
        company: "华为技术有限公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2022-L-10232780",
        success_date: "2022-10-13",
      },
    ],
  },
  {
    creditNo: "91330201764518889H",
    copyrights: [
      {
        type_num: "-",
        related_companies: [],
        id: "5f0eb6b7ebf5519714009248",
        first_date: "-",
        name: "拥抱深林",
        created_time: "2020-07-15",
        version: "-",
        type_name: "美术",
        approval_date: "2012-06-18",
        short_name: "-",
        company: "苹果（中国）有限公司",
        last_updated_time: "2020-07-15",
        type: "P",
        number: "11-2012-F-7358",
        success_date: "-",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "5def6c45ebf5513f130086d8",
        first_date: "-",
        name: "深林生态负离子",
        created_time: "2019-12-10",
        version: "-",
        type_name: "美术",
        approval_date: "2012-06-18",
        short_name: "-",
        company: "苹果（中国）有限公司",
        last_updated_time: "2019-12-10",
        type: "P",
        number: "浙作登字-2012-F-00007359",
        success_date: "2012-06-04",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "5def6c4579112e4377009874",
        first_date: "-",
        name: "拥抱深林",
        created_time: "2019-12-10",
        version: "-",
        type_name: "美术",
        approval_date: "2012-06-18",
        short_name: "-",
        company: "苹果（中国）有限公司",
        last_updated_time: "2019-12-10",
        type: "P",
        number: "浙作登字-2012-F-00007358",
        success_date: "2012-06-04",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "5649b65ed9feb1a6543619dc",
        first_date: "-",
        name: "精彩封面",
        created_time: "2016-02-17",
        version: "-",
        type_name: "美术",
        approval_date: "2009-07-14",
        short_name: "-",
        company: "苹果（中国）有限公司",
        last_updated_time: "2021-03-31",
        type: "P",
        number: "2009-F-017445",
        success_date: "2009-05-25",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "5f0eb2e6ebf551451500744c",
        first_date: "-",
        name: "苹果专利标志（LOGO）",
        created_time: "2020-07-15",
        version: "-",
        type_name: "美术",
        approval_date: "2009-05-21",
        short_name: "-",
        company: "苹果（中国）有限公司",
        last_updated_time: "2020-07-15",
        type: "P",
        number: "11-2009-F-2361",
        success_date: "-",
      },
    ],
  },
  {
    creditNo: "91350500768588454G",
    copyrights: [
      {
        type_num: "-",
        related_companies: [],
        id: "622e4b0fb3eb8d8e96c26284",
        first_date: "2021-11-15",
        name: "2021鹿蜀计划LOGO",
        created_time: "2022-04-13",
        version: "-",
        type_name: "美术",
        approval_date: "2022-03-02",
        short_name: "-",
        company: "华为技术有限公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2022-F-10046440",
        success_date: "2021-10-20",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "622e4af8bb484671e20ce192",
        first_date: "2021-11-15",
        name: "鹿小蜀",
        created_time: "2022-04-07",
        version: "-",
        type_name: "美术",
        approval_date: "2022-02-23",
        short_name: "-",
        company: "华为技术有限公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2022-F-10040887",
        success_date: "2021-08-30",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "622e4afe9c58230ccc83a4cf",
        first_date: "2021-07-30",
        name: "猫镇布鲁斯",
        created_time: "2022-04-07",
        version: "-",
        type_name: "美术",
        approval_date: "2022-01-29",
        short_name: "-",
        company: "华为技术有限公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2022-F-10023250",
        success_date: "2021-07-20",
      },
    ],
  },
  {
    creditNo: "91110108558521630L",
    copyrights: [
      {
        type_num: "-",
        related_companies: [],
        id: "6347bc12f2aaeca7378a2ba6",
        first_date: "-",
        name: "米家-欢洗伴履",
        created_time: "2022-10-13",
        version: "-",
        type_name: "美术",
        approval_date: "2022-06-29",
        short_name: "-",
        company: "小米通讯技术有限公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "川作登字-2022-F-00092431",
        success_date: "2022-06-01",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "6347bc12f2aaeca7378a2ba7",
        first_date: "-",
        name: "废弃物分类回收系列设计图",
        created_time: "2022-10-13",
        version: "-",
        type_name: "美术",
        approval_date: "2022-05-11",
        short_name: "-",
        company: "小米通讯技术有限公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2022-F-10096360",
        success_date: "2021-12-28",
      },
    ],
  },
  {
    creditNo: "914420006947806297",
    copyrights: [
      {
        type_num: "-",
        related_companies: [],
        id: "5a277f79db5ca099ca713e54",
        first_date: "-",
        name: "HAVAL",
        created_time: "2017-12-06",
        version: "-",
        type_name: "美术",
        approval_date: "2017-08-07",
        short_name: "-",
        company: "中山市西沐电器有限公司",
        last_updated_time: "2020-11-15",
        type: "P",
        number: "国作登字-2017-F-00483752",
        success_date: "2010-10-25",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "5a277f79db5ca099ca713e52",
        first_date: "-",
        name: "小米",
        created_time: "2017-12-06",
        version: "-",
        type_name: "美术",
        approval_date: "2017-08-07",
        short_name: "-",
        company: "中山市西沐电器有限公司",
        last_updated_time: "2020-11-15",
        type: "P",
        number: "国作登字-2017-F-00483753",
        success_date: "2010-10-25",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "5968c57f8587822df6bc528b",
        first_date: "-",
        name: "XIAOMI",
        created_time: "2017-07-14",
        version: "-",
        type_name: "美术",
        approval_date: "2017-03-31",
        short_name: "-",
        company: "中山市西沐电器有限公司",
        last_updated_time: "2020-11-15",
        type: "P",
        number: "国作登字-2017-F-00378674",
        success_date: "2010-10-20",
      },
    ],
  },
  {
    creditNo: "91110302587727910P",
    copyrights: [
      {
        type_num: "-",
        related_companies: [],
        id: "5e9e473279112eb64d002d44",
        first_date: "2018-03-20",
        name: "微磁振图",
        created_time: "2020-04-21",
        version: "-",
        type_name: "美术",
        approval_date: "2019-09-10",
        short_name: "-",
        company: "苹果（中国）有限公司",
        last_updated_time: "2021-03-17",
        type: "P",
        number: "国作登字-2019-F-00881130",
        success_date: "2018-02-10",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "5e9e473179112eb64d002d42",
        first_date: "2017-09-30",
        name: "苹果磁振图",
        created_time: "2020-04-21",
        version: "-",
        type_name: "美术",
        approval_date: "2019-09-10",
        short_name: "-",
        company: "苹果（中国）有限公司",
        last_updated_time: "2021-03-17",
        type: "P",
        number: "国作登字-2019-F-00881131",
        success_date: "2017-06-16",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "5f0eb6b979112e7f7e006129",
        first_date: "-",
        name: "深林生态负离子",
        created_time: "2020-07-15",
        version: "-",
        type_name: "美术",
        approval_date: "2012-06-18",
        short_name: "-",
        company: "苹果（中国）有限公司",
        last_updated_time: "2020-07-15",
        type: "P",
        number: "11-2012-F-7359",
        success_date: "-",
      },
    ],
  },
  {
    creditNo: "91110400MA04ENG447",
    copyrights: [
      {
        type_num: "-",
        related_companies: [],
        id: "62eb84ea63fb28df94b4adf9",
        first_date: "2021-12-15",
        name: "华为价值定价V⁴P⁴ LOGO",
        created_time: "2022-09-05",
        version: "-",
        type_name: "美术",
        approval_date: "2022-07-04",
        short_name: "-",
        company: "华为技术有限公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2022-F-10130447",
        success_date: "2021-12-15",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "62eb84e5c6f5b4036a897599",
        first_date: "2021-12-15",
        name: "华为价值定价V⁴P⁴方法论",
        created_time: "2022-09-05",
        version: "-",
        type_name: "其他",
        approval_date: "2022-07-04",
        short_name: "-",
        company: "华为技术有限公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2022-L-10130448",
        success_date: "2021-12-15",
      },
    ],
  },
  {
    creditNo: "91330103580267747M",
    copyrights: [
      {
        type_num: "-",
        related_companies: [],
        id: "6470635347402081d2f313de",
        first_date: "2023-03-31",
        name: "华为培训评估体系VALUE模型",
        created_time: "2023-05-26",
        version: "-",
        type_name: "地图、示意图",
        approval_date: "2023-05-23",
        short_name: "-",
        company: "华为技术有限公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2023-K-00097242",
        success_date: "2023-03-31",
      },
      {
        type_num: "-",
        related_companies: [],
        id: "643e495556e314cc8f465797",
        first_date: "2023-01-16",
        name: "华为技术培训：5GtoB产品销售训战项目学习旅程",
        created_time: "2023-04-18",
        version: "-",
        type_name: "地图、示意图",
        approval_date: "2023-04-13",
        short_name: "-",
        company: "华为技术有限公司",
        last_updated_time: "1970-01-01",
        type: "P",
        number: "国作登字-2023-K-00064443",
        success_date: "2023-01-16",
      },
    ],
  },
];
export default copyrights;
