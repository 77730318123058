import { Form, Input, Button, Table } from "antd";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "./query.module.css";
import PageHeader from "../../components/PageHeader";
import allcopyrights from "../../data.js/allcopyrights";
export default function Patents() {
  return (
    <>
      <PageHeader
        title="著作权数据检索"
        description="检索入驻园区企业的相关著作权信息。"
      />
      <div className={styles.timestamp}>
        最后更新时间：2023年6月12日 23:00:00
      </div>
      <div className={styles.actions}>
        <Form layout="inline">
          <Form.Item name="keywords">
            <Input style={{ width: "16rem" }} placeholder="著作权名称/登记号" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              检索
            </Button>
          </Form.Item>
        </Form>
        <div>
          <Button type="primary" icon={<DownloadOutlined />}>
            导出报表
          </Button>
        </div>
      </div>
      <div className={styles.section}>
        <Table
          columns={[
            {
              title: "著作权名称",
              dataIndex: "name",
            },
            {
              title: "登记号",
              dataIndex: "number",
            },
            {
              title: "作品分类",
              dataIndex: "type",
            },
            {
              title: "创作时间",
              dataIndex: "created_time",
            },
            {
              title: "发布时间",
              dataIndex: "approval_date",
            },
            {
              title: "登记人",
              dataIndex: "request_num",
            },
            {
              title: "登记时间",
              dataIndex: "first_date",
            },
          ]}
          rowKey="id"
          dataSource={allcopyrights}
        />
      </div>
    </>
  );
}
