import { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import { range, random } from 'lodash';

function Chart3() {
  const element = useRef();
  useEffect(() => {
    
    const chart = new Chart(element.current, {
      type: 'line',
      data: {
        labels: range(5).map(index => `${2019 + index}年`),
        datasets: [
          {
            label: '最近5年',
            data: range(5).map(() => random(417, 433)),
            fill: true,
            borderColor: '#e76f51'
          }
        ]
      },
      options: {
        scales: {
          y: { 
            min: 400,
            max: 440,
            ticks: { stepSize: 1 } 
          }
        },
        aspectRatio: 1.5,
        layout: {
          // padding: 16
        },
        plugins: {
          legend: false
        }
      }
    });
    return () => chart.destroy();
  }, []);
  return (
    <canvas ref={element} />
  );
}

export default Chart3;