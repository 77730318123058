import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, Space, Tabs } from 'antd';
import { CheckCircleOutlined, HistoryOutlined, MessageOutlined } from '@ant-design/icons';
import PageHeader from '../../components/PageHeader';
import CreateModal from './Create.Modal';
import styles from './empowerment.module.css';

export default function Details() {
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  return (
    <div className={styles.root}>
      <PageHeader
        // title="品牌托管"
        // description="查阅来自企业品牌相关的咨询内容，第一时间了解企业需求，向企业输出专业的品牌服务。"
        breadcrumb={[
          { title: <Link to="/dfmg/empowerment">品牌赋能</Link> },
          { title: "知识产权" },
        ]}
      />
      <div className={styles.header}>
        <div className={styles.cover}>
          <img src="/cover/知识产权.png" alt="封面" />
          <div className={styles.meta}>
            <p>服务代码：202300010001</p>
            <p>温馨提示：本服务由环球互通提供支持</p>
          </div>
        </div>
        <div className={styles.content}>
          <Typography>
            <Typography.Title level={2}>知识产权</Typography.Title>
            <Typography.Paragraph type="secondary">提供高效、专业的知识产权服务<br />为您的知识产权保驾护航</Typography.Paragraph>
          </Typography>
          <div className={styles.highlights}>
            <div className={styles.stamp} />
            <div className={styles.item}><CheckCircleOutlined />不成功，全额退款</div>
            <div className={styles.item}><CheckCircleOutlined />最快一个工作日内申报</div>
            <div className={styles.item}><CheckCircleOutlined />提供一对一的管家式服务</div>
            <div className={styles.item}><CheckCircleOutlined />专业服务，成功率更有保障</div>
          </div>
          <div className={styles.actions}>
            <Typography>
              <Typography.Paragraph type="secondary"><HistoryOutlined style={{ marginRight: '.25rem' }} />最近一周内有3位客户咨询过</Typography.Paragraph>
            </Typography>
            <Space>
              <Button type="primary" icon={<MessageOutlined />} size="large" onClick={ () => setIsModalOpen(true) }>立即咨询</Button>
              <Button type="primary" size="large" ghost>咨询电话：400-006-2018</Button>
              <CreateModal
                open={isModalOpen}
                onOk={ () => setIsModalOpen(false) }
                onCancel={ () => setIsModalOpen(false) }
              />
            </Space>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <Tabs items={[{
          key: '1',
          label: '服务介绍',
          children: <div className={styles.illustration} />
        }]} />
      </div>
    </div>
  );
}