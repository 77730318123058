import {
  Row,
  Col,
  Anchor,
  Typography,
  Descriptions,
  Tag,
  Table,
  Avatar,
} from "antd";
import { Link, useLocation } from "react-router-dom";
import { NumberOutlined, LinkOutlined } from "@ant-design/icons";
import { gt } from "lodash";
import PageHeader from "../../components/PageHeader";
import Tags from '../../components/Tags';
import styles from "./query.module.css";
import company from "../../data.js/company";
import relation from "../../data.js/relation";
import brands from "../../data.js/brands";
import patents from "../../data.js/patents";
import copyrights from "../../data.js/copyrights";
// import risks from "../../data.js/risks";
import industries from "../../data.js/industries";
import { useEffect, useState } from "react";


export default function Company(props) {
  const generateDate = () => {
    const year = 2000 + Math.floor(Math.random() * 23);
    const month = Math.floor(Math.random() * 12) + 1; // 1到12之间的随机月份
    const day = Math.floor(Math.random() * 28) + 1; // 1到28之间的随机日期

    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
  };
  
  const location = useLocation();
  const { state } = location;
  const [mockCompany, setMockCompany] = useState({});
  const [mockRelation, setMockRelation] = useState({});
  const [mockBrands, setMockBrands] = useState({});
  const [mockPatents, setMockPatents] = useState({});
  const [mockCopyrights, setMockCopyrights] = useState({});
  const [mockIndustries, setMockIndustries] = useState({});
  const [risks, setRisks] = useState([]);

  useEffect(() => {
    const generateRisks = (num) => {
      const risks = [];
      const usedDates = new Set();
  
      for (let i = 1; i <= num; i++) {
        let createdAt;
        do {
          createdAt = generateDate();
        } while (usedDates.has(createdAt));
        usedDates.add(createdAt);
  
        const risk = {
          id: i,
          createdAt: createdAt,
          category: "新增开庭公告",
          level: Math.floor(Math.random() * 2),
          description:
            "新增一则身份为被告的开庭公告，案由为买卖合同纠纷，将于2023-07-06开庭",
        };
  
        risks.push(risk);
      }
  
      return risks;
    };
    
    setMockCompany(
      company.find((item) => {
        return item.creditNo === state.sn;
      })
    );
    setMockRelation(
      relation.find((item) => {
        return item.creditNo === state.sn;
      })
    );
    setMockBrands(
      brands.find((item) => {
        return item.creditNo === state.sn;
      })
    );
    setMockPatents(
      patents.find((item) => {
        return item.creditNo === state.sn;
      })
    );
    setMockCopyrights(
      copyrights.find((item) => {
        return item.creditNo === state.sn;
      })
    );
    setMockIndustries(
      industries.find((item) => {
        return item.creditNo === state.sn;
      })
    );
    setRisks(generateRisks(state.risks));
  }, [state]);
  // console.log("mockCompany :>> ", mockCompany);
  // console.log("mockRelation :>> ", mockRelation);
  // console.log("mockBrands :>> ", mockBrands);

  return (
    <>
      <PageHeader
        title={mockCompany.name}
        description="展示企业的各种公开信息，包括：基本信息、经营信息、知识产权等。"
        breadcrumb={[
          { title: <Link to="/1/query/companies">企业数据检索</Link> },
          { title: `${mockCompany.name}` },
        ]}
      />
      <Tags />
      <Row gutter={32}>
        <Col span={18}>
          <Typography.Title className={styles.title} id="基本信息" level={5}>
            <NumberOutlined />
            基本信息
          </Typography.Title>
          <Descriptions
            column={2}
            className={styles.descriptions}
            labelStyle={{ whiteSpace: "nowrap" }}
            bordered
          >
            <Descriptions.Item label="公司名称">
              {mockCompany.name}
            </Descriptions.Item>
            <Descriptions.Item label="法定代表人">
              {mockCompany.operName}
            </Descriptions.Item>
            <Descriptions.Item label="统一社会信用代码">
              {mockCompany.creditNo}
            </Descriptions.Item>
            <Descriptions.Item label="经营状态">
              <Tag color="#1B9C85">{mockCompany.new_status}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="所属行业">
              {mockIndustries.industry_name}
            </Descriptions.Item>
            <Descriptions.Item label="成立日期">
              {mockCompany.startDate}
            </Descriptions.Item>
            <Descriptions.Item label="企业类型">
              {mockCompany.econKind}
            </Descriptions.Item>
            <Descriptions.Item label="营业期限"></Descriptions.Item>
            <Descriptions.Item label="登记机关">
              {mockCompany.belongOrg}
            </Descriptions.Item>
            <Descriptions.Item label="核准日期">
              {mockCompany.checkDate}
            </Descriptions.Item>
            <Descriptions.Item label="注册地址" span={2}>
              {mockCompany.address}
            </Descriptions.Item>
            <Descriptions.Item label="经营范围" span={2}>
              {mockCompany.scope}
            </Descriptions.Item>
          </Descriptions>

          <Typography.Title className={styles.title} id="联系方式" level={5}>
            <NumberOutlined />
            联系方式
          </Typography.Title>
          <Descriptions
            column={2}
            className={styles.descriptions}
            labelStyle={{ whiteSpace: "nowrap" }}
            bordered
          >
            <Descriptions.Item label="联系电话">
              {mockRelation.telephone}
            </Descriptions.Item>
            <Descriptions.Item label="邮箱">
              {mockRelation.email}
            </Descriptions.Item>
            <Descriptions.Item label="地址" span={2}>
              {mockRelation.address}
            </Descriptions.Item>
          </Descriptions>

          <Typography.Title className={styles.title} id="经营风险" level={5}>
            <NumberOutlined />
            经营风险
          </Typography.Title>
          <div className={styles.section}>
            <Table
              columns={[
                {
                  title: "时间",
                  dataIndex: "createdAt",
                },
                {
                  title: "类型",
                  dataIndex: "category",
                },
                {
                  title: "等级",
                  dataIndex: "level",
                  render: (data) =>
                    data === 0 ? (
                      <Tag color="green">低风险</Tag>
                    ) : (
                      <Tag color="red">高风险</Tag>
                    ),
                },
                {
                  title: "描述",
                  dataIndex: "description",
                },
              ]}
              dataSource={risks}
              rowKey="id"
            />
          </div>

          <Typography.Title className={styles.title} id="商标" level={5}>
            <NumberOutlined />
            商标
          </Typography.Title>
          <div className={styles.section}>
            <Table
              columns={[
                {
                  title: "商标",
                  dataIndex: "image_name",
                  render: (data, record) => (
                    <img
                      src={data}
                      alt="xiaomi"
                      style={{ width: "50px" }}
                    ></img>
                  ),
                },
                {
                  title: "商标名称",
                  dataIndex: "name",
                },
                {
                  title: "注册号",
                  dataIndex: "reg_number",
                },
                {
                  title: "分类",
                  dataIndex: "type_name",
                },
                {
                  title: "注册人",
                  dataIndex: "company",
                },
                {
                  title: "注册时间",
                  dataIndex: "apply_date",
                },
              ]}
              dataSource={mockBrands.brands}
              rowKey="id"
            />
          </div>

          <Typography.Title className={styles.title} id="专利" level={5}>
            <NumberOutlined />
            专利
          </Typography.Title>
          <div className={styles.section}>
            <Table
              columns={[
                {
                  title: "专利名称",
                  dataIndex: "patent_name",
                },
                {
                  title: "申请号",
                  dataIndex: "request_num",
                },
                {
                  title: "类型",
                  dataIndex: "type_name",
                },
                {
                  title: "法律状态",
                  dataIndex: "last_status",
                },
                {
                  title: "申请人",
                  dataIndex: "app_person",
                },
                {
                  title: "申请时间",
                  dataIndex: "request_date",
                },
              ]}
              dataSource={mockPatents.patents}
              rowKey="id"
            />
          </div>

          <Typography.Title className={styles.title} id="著作权" level={5}>
            <NumberOutlined />
            著作权
          </Typography.Title>
          <div className={styles.section}>
            <Table
              columns={[
                {
                  title: "著作权名称",
                  dataIndex: "name",
                },
                {
                  title: "登记号",
                  dataIndex: "id",
                },
                {
                  title: "作品分类",
                  dataIndex: "type_name",
                },
                {
                  title: "创作时间",
                  dataIndex: "success_date",
                },
                {
                  title: "发布时间",
                  dataIndex: "approval_date",
                },
                {
                  title: "登记人",
                  dataIndex: "company",
                },
                {
                  title: "登记时间",
                  dataIndex: "first_date",
                },
              ]}
              dataSource={mockCopyrights.copyrights}
              rowKey="id"
            />
          </div>

          <Typography.Title className={styles.title} id="业务咨询" level={5}>
            <NumberOutlined />
            业务咨询
          </Typography.Title>
          <div className={styles.section}>
            <Table
              columns={[
                {
                  title: "订单号",
                  dataIndex: "id",
                  render: (data) => (
                    <Link to="#">
                      <LinkOutlined style={{ marginRight: ".25rem" }} />
                      {data}
                    </Link>
                  ),
                  sorter: (a, b) => (gt(a, b) ? 1 : -1),
                },
                {
                  title: "业务名称",
                  dataIndex: "name",
                  render: (data) => (
                    <div>
                      <Avatar
                        style={{
                          marginRight: ".5rem",
                          backgroundColor: "#333333",
                        }}
                      >
                        S
                      </Avatar>
                      {data}
                    </div>
                  ),
                  sorter: (a, b) => (gt(a, b) ? 1 : -1),
                },
                {
                  title: "咨询人",
                  dataIndex: "client",
                },
                {
                  title: "手机",
                  dataIndex: "mobile",
                },
                {
                  title: "公司",
                  dataIndex: "company",
                },
                {
                  title: "服务状态",
                  dataIndex: "status",
                  render: (data) => <Tag color="#87d068">待受理</Tag>,
                },
                {
                  title: "咨询时间",
                  dataIndex: "createdAt",
                },
                {
                  title: "最后更新时间",
                  dataIndex: "updatedAt",
                },
              ]}
              rowKey="id"
            />
          </div>
        </Col>
        <Col span={6}>
          <Anchor
            offsetTop={96}
            items={[
              { key: "1", href: "#基本信息", title: "基本信息" },
              { key: "2", href: "#联系方式", title: "联系方式" },
              { key: "3", href: "#经营风险", title: "经营风险" },
              { key: "4", href: "#商标", title: "商标" },
              { key: "5", href: "#专利", title: "专利" },
              { key: "6", href: "#著作权", title: "著作权" },
              { key: "7", href: "#业务咨询", title: "业务咨询" },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}
