const patentInfo = [
  {
    patent_name: "手机壳",
    last_status: "授权",
    brief:
      "1.本外观设计产品的名称手机壳。2.本外观设计产品的用途用于一种手机壳，例如折叠式手机的手机壳，组件1和组件2分别套设在手机的两个半部上。3.本外观设计产品的设计要点在于形状。4.最能表明设计要点的图片或照片组件1立体图1。5.本申请的组件产品为不固定组合方式。",
    patent_person: "北京小米科技有限责任公司",
    request_num: "CN202130158176.0",
    authorize_tag: "2",
    designer: "杨帅",
    type_name: "中国外观设计专利",
    agent: "北京律智知识产权代理有限公司",
    outhor_num: "-",
    annex:
      "https://qxb-img-osscache.qixin.com/patents_pdf_new/bcd32d3a4a8bdcd493bef39c8b6eacc0.pdf",
    category_num: "03-01(13)",
    outhor_date: "-",
    request_date: "2021-03-23",
    agent_people: "孙宝海;阚梓瑄",
  },
  {
    patent_name: "IDC检测方法和装置、IDC检测指示方法和装置",
    last_status: "实质审查的生效",
    brief:
      "本公开涉及IDC检测方法，包括根据基站发送的频点指示信息确定需要检测IDC的待测载波频点；根据所述基站发送的带宽指示信息确定所述待测载波频点对应的待测带宽；根据所述待测载波频点和所述待测带宽确定待测频段；检测所述待测频段是否存在或将要出现IDC。根据本公开的实施例，一方面减少了需要终端检测的带宽，另一方面使得终端向基站上报的IDC信息更为精确，便于基站根据IDC信息对终端进行合理的配置。",
    patent_person: "小米通讯技术有限公司",
    request_num: "CN201980001884.3",
    authorize_tag: "0",
    designer: "江小威",
    type_name: "中国发明专利",
    agent: "北京博思佳知识产权代理有限公司",
    outhor_num: "CN110741669A",
    annex:
      "https://qxb-img-osscache.qixin.com/patents_pdf_new/574685866de16a72cf40b2e96201572a.pdf",
    category_num: "H04W24/00(2009.01)I;H04W16/14(2009.01)I;H04W72/12(2009.01)I",
    outhor_date: "2020-01-31",
    request_date: "2019-09-03",
    agent_people: "艾佳",
  },
  {
    patent_name: "带显示界面的净水器",
    last_status: "授权",
    brief:
      "1．本外观设计产品的名称带显示界面的净水器；2．本外观设计产品的用途用于显示饮水机当前的运行状态，及控制智能饮水机通过连接无线路由器连接互联网，其中，无线网络标记的灯为橙色时表示开机中、无线网络待连接，无线网络标记的指示灯为蓝色时表示与无线网络连接，数字标记的指示灯为橙色时表示智能饮水机中的滤芯的寿命提示，扳手标记的指示为橙色时表示智能饮水机异常显示；3．本外观设计产品的设计要点在于屏幕中的用户界面的界面内容；即A处的用户界面内容；4.其它视图无设计要点，故省略其它视图；5．最能表明本外观设计设计要点的图片或照片俯视图。",
    patent_person: "佛山市云米电器科技有限公司;小米科技有限责任公司;陈小平",
    request_num: "CN201630236344.2",
    authorize_tag: "2",
    designer: "陈小平;李宁宁;曹大伟",
    type_name: "中国外观设计专利",
    agent: "佛山市名诚专利商标事务所（普通合伙）",
    outhor_num: "-",
    annex:
      "https://qxb-img-osscache.qixin.com/patents_pdf_new/cdefad05d4d9e620bedf39fb671841be.pdf",
    category_num: "23-01(10);14-04(10)",
    outhor_date: "-",
    request_date: "2016-06-12",
    agent_people: "卢志文",
  },
  {
    patent_name: "一种基于5G网络的信息安全鉴权方法及系统",
    last_status: "公布",
    brief:
      "本发明公开了一种基于5G网络的信息安全鉴权方法及系统，用于5G网络下不同节点之间的鉴权，方法包括初始化配置；鉴权线索传递；根据请求进行鉴权。本发明通过预设的丢包率控制鉴权线索每次传递后的损耗，由于发起节点所在的虚拟网络是确定的，每次传递后的丢包率是确定的，因此可以判断鉴权线索的完整率是否符合真实的传递情况。在本申请的鉴权措施下，外部访问者想要仿冒身份，需要知道完整鉴权线索、丢包率、传递次数等，并且缺少任何消息都无法通过鉴权，而这些信息本身并不在网络中传递，难以获取，基本杜绝了仿冒的可能性，因此安全性极高。",
    patent_person:
      "国网浙江省电力有限公司信息通信分公司;国网浙江省电力有限公司杭州供电公司;华为技术有限公司;中国移动通信集团浙江有限公司",
    request_num: "CN202310490011.1",
    authorize_tag: "0",
    designer:
      "黄红兵;邵炜平;李昂;章毅;汤亿则;邱兰馨;钱锦;王玮;王俊伟;王法辉;刘秀龙;饶子仁;荣宁;樊兆宾;沈秋泉;胡少清;杜猛俊;王源涛;王剑;张吉;张烨华",
    type_name: "中国发明专利",
    agent: "杭州华鼎知识产权代理事务所（普通合伙）",
    outhor_num: "CN116193432A",
    annex:
      "https://qxb-img-osscache.qixin.com/patents_pdf_new/5c2f5cd9a545b14e40eba13d502e1f5a.pdf",
    category_num: "H04W12/06(2021.01)I;H04W12/60(2021.01)I",
    outhor_date: "2023-05-30",
    request_date: "2023-05-04",
    agent_people: "魏亮",
  },
  {
    patent_name: "带显示界面的净水器",
    last_status: "授权",
    brief:
      "1．本外观设计产品的名称带显示界面的净水器；2．本外观设计产品的用途用于显示饮水机当前的运行状态，及控制智能饮水机通过连接无线路由器连接互联网，其中，无线网络标记的灯为橙色时表示开机中、无线网络待连接，无线网络标记的指示灯为蓝色时表示与无线网络连接，数字标记的指示灯为橙色时表示智能饮水机中的滤芯的寿命提示，扳手标记的指示为橙色时表示智能饮水机异常显示；3．本外观设计产品的设计要点在于屏幕中的用户界面的界面内容；即A处的用户界面内容；4.其它视图无设计要点，故省略其它视图；5．最能表明本外观设计设计要点的图片或照片俯视图。",
    patent_person: "佛山市云米电器科技有限公司;小米科技有限责任公司;陈小平",
    request_num: "CN201630236344.2",
    authorize_tag: "2",
    designer: "陈小平;李宁宁;曹大伟",
    type_name: "中国外观设计专利",
    agent: "佛山市名诚专利商标事务所（普通合伙）",
    outhor_num: "-",
    annex:
      "https://qxb-img-osscache.qixin.com/patents_pdf_new/cdefad05d4d9e620bedf39fb671841be.pdf",
    category_num: "23-01(10);14-04(10)",
    outhor_date: "-",
    request_date: "2016-06-12",
    agent_people: "卢志文",
  },
  {
    patent_name: "一种物联网边缘环境下通感一体化智能传输与调控方法",
    last_status: "实质审查的生效",
    brief:
      "本发明公开了一种物联网边缘环境下通感一体化智能传输与调控方法，涉及物联网与通感一体化技术领域，本方法中服务基站部署在物联网边缘环境下，服务基站与多个设备进行通信，同时对感知多个设备；具体步骤如下服务基站感知设备，获取感知参数；利用感知参数，对传输模型进行重新构建；利用感知参数，对多径衰落模型进行重新构建；信道模型重构后，服务基站为环境内设备构建优化算法模型；本发明通过感知辅助通信，服务基站动态的更新优化算法，确保达到通信性能和感知性能的均衡。",
    patent_person: "南京邮电大学;东南大学;华为技术有限公司",
    request_num: "CN202310207125.0",
    authorize_tag: "0",
    designer: "朱洪波;金石;孙建平;赵海涛;张晖;丁中正;黄传娜",
    type_name: "中国发明专利",
    agent: "南京经纬专利商标代理有限公司",
    outhor_num: "CN116073928A",
    annex:
      "https://qxb-img-osscache.qixin.com/patents_pdf_new/996d96c8fbceae13a52f9b80cc23a77f.pdf",
    category_num:
      "H04B17/391(2015.01)I;H04B17/327(2015.01)I;H04B17/382(2015.01)I",
    outhor_date: "2023-05-05",
    request_date: "2023-03-07",
    agent_people: "胡杰",
  },
];
export default patentInfo;
