const companies = [
  {
    id: "534472fd-7d53-4958-8132-d6a6242423d8",
    name: "小米科技有限责任公司",
    reg_no: "110108012660422",
    start_date: "2010-03-03",
    matchItems: "Xiaomi Inc.",
    credit_no: "91110108551385082Q",
    oper_name: "雷军",
    matchType: "企业英文名称",
    type: 0,
  },
  {
    id: "4c8bd41b-2534-43c0-8643-5bf4dca1991e",
    name: "华为技术有限公司",
    reg_no: "440301103097413",
    start_date: "1987-09-15",
    matchItems: "华为技术有限公司",
    credit_no: "914403001922038216",
    oper_name: "赵明路",
    matchType: "企业名称",
    type: 0,
  },
  {
    id: "9e3eb0d8-d77e-40e0-a3dd-7020231841c4",
    name: "苹果（中国）有限公司",
    reg_no: "330200400073286",
    start_date: "2004-09-07",
    matchItems: "苹果（中国）有限公司",
    credit_no: "91330201764518889H",
    oper_name: "施银焕",
    matchType: "企业名称",
    type: 0,
  },
  {
    id: "efa2ce15-21e4-48e8-ae0b-fb9cac8a5f41",
    name: "金苹果（中国）有限公司",
    reg_no: "350500400003093",
    start_date: "2005-01-18",
    matchItems: "金苹果（中国）有限公司",
    credit_no: "91350500768588454G",
    oper_name: "庄春龙",
    matchType: "企业名称",
    type: 0,
  },
  {
    id: "de7f2292-4482-4790-92b3-b1564c636bd5",
    name: "小米通讯技术有限公司",
    reg_no: "110000450147982",
    start_date: "2010-08-25",
    matchItems: "Xiaomi Communications Co., Ltd",
    credit_no: "91110108558521630L",
    oper_name: "王川",
    matchType: "企业英文名称",
    type: 0,
  },
  {
    id: "99e34a29-0425-48b4-9ffc-1e1171347b97",
    name: "中山市西沐电器有限公司",
    reg_no: "442000000270608",
    start_date: "2009-09-30",
    matchItems: "XIAOMI",
    credit_no: "914420006947806297",
    oper_name: "刘小秀",
    matchType: "商标信息",
    type: 0,
  },
  {
    id: "75d254b0-1de0-41f3-b940-2547591dd982",
    name: "北京小米电子产品有限公司",
    reg_no: "110000450195272",
    start_date: "2012-01-09",
    matchItems: "Beijing Xiaomi Electronics Co., Ltd.",
    credit_no: "91110302587727910P",
    oper_name: "张峰",
    matchType: "企业英文名称",
    type: 0,
  },
  {
    id: "a1a56ca3-0337-4fca-a61c-c1090daba1e1",
    name: "小米汽车有限公司",
    reg_no: "110400031798725",
    start_date: "2021-09-01",
    matchItems: "Xiaomi EV Limited",
    credit_no: "91110400MA04ENG447",
    oper_name: "雷军",
    matchType: "股东",
    type: 0,
  },
  {
    id: "bcb46eb3-e206-457c-9462-d38b86954ed7",
    name: "杭州小米环境科技有限公司",
    reg_no: "330106000188633",
    start_date: "2011-08-17",
    matchItems: "XIAOMI",
    credit_no: "91330103580267747M",
    oper_name: "梅慧",
    matchType: "商标信息",
    type: 0,
  },
];
export default companies;
