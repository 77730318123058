import { Row, Col, Statistic, Button } from "antd";
import styles from "./kanban.module.css";
import { Chart12, Chart13, Chart14, Chart15 } from "../../components/charts";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";

const span = { xs: 24, sm: 24, md: 24, lg: 12, xl: 8, xxl: 8 };

export default function Kanban1() {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader
        title="专利数据统计"
        description="针对入驻园区企业的专利、PCT专利等数据的统计看板。"
      />
      <div className={styles.timestamp}>
        最后更新时间：2023年6月12日 23:00:00
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className={styles.section}>
            <div className={styles.statistic}>
              <div className={styles.item}>
                <Statistic title="专利注册数量" value={4286} />
                <Button
                  type="primary"
                  onClick={() => navigate("/1/query/patents")}
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="PCT专利注册数量" value={2} />
                <Button
                  type="primary"
                  onClick={() => navigate("/1/query/patents")}
                >
                  查看详情
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>专利注册类型</h2>
              <Chart12 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>PCT专利注册类型</h2>
              <Chart13 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>专利申请/注册数量（近5年）</h2>
              <Chart14 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>PCT专利注册数量（近5年）</h2>
              <Chart15 />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
