import { Form, Input, Button, Table, Tag } from "antd";
import { Link } from "react-router-dom";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "./query.module.css";
import PageHeader from "../../components/PageHeader";
import allpatents from "../../data.js/allpatents";
export default function Patents() {
  return (
    <>
      <PageHeader
        title="专利数据检索"
        description="检索入驻园区企业的相关专利信息。"
      />
      <div className={styles.timestamp}>
        最后更新时间：2023年6月12日 23:00:00
      </div>
      <div className={styles.actions}>
        <Form layout="inline">
          <Form.Item name="keywords">
            <Input style={{ width: "16rem" }} placeholder="专利名称/申请号" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              检索
            </Button>
          </Form.Item>
        </Form>
        <div>
          <Button type="primary" icon={<DownloadOutlined />}>
            导出报表
          </Button>
        </div>
      </div>
      <div className={styles.section}>
        <Table
          columns={[
            {
              title: "专利名称",
              dataIndex: "patent_name",
              render: (data, record) => (
                // <Link
                //   to={`/1/query/patents/6df7b4e4e2b64bc1b7e6f91f5f456ab5`}
                //   state={record}
                // >
                //   {data}
                // </Link>
                <Link to={`/1/query/patents/${record.id}`} state={record}>
                  {data}
                </Link>
              ),
            },
            {
              title: "申请号",
              dataIndex: "request_num",
            },
            {
              title: "类型",
              dataIndex: "type_name",
            },
            {
              title: "法律状态",
              dataIndex: "last_status",
            },
            {
              title: "是否授权",
              dataIndex: "authorize_tag",
              render: (data) =>
                !data ? <Tag>未授权</Tag> : <Tag color="#1B9C85">已授权</Tag>,
            },
            {
              title: "申请人",
              dataIndex: "app_person",
            },
            {
              title: "申请时间",
              dataIndex: "request_date",
            },
          ]}
          rowKey="id"
          dataSource={allpatents}
        />
      </div>
    </>
  );
}
