import { Form, Input, Button, Table, Typography } from "antd";
import { Link } from "react-router-dom";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "./monitoring.module.css";

import PageHeader from "../../components/PageHeader";
import companies from "../../data.js/companies";
import brands from "../../data.js/brands";
import patents from "../../data.js/patents";

// import company from "../../data.js/company";
const getlength = (sn, category, prop) => {
  const result = category.find((item) => {
    return item.creditNo === sn;
  });

  return result[prop].length;
  // console.log("result :>> ", result[prop]);
};
const mockDataSource = companies.map((item, index) => ({
  id: index + 1,
  name: item.name,
  sn: item.credit_no,
  industry: "信息技术服务",
  risks: getlength(item.credit_no, brands, "brands") + 3,
  trademarkRisks:
    getlength(item.credit_no, brands, "brands") - 1 < 0
      ? 0
      : getlength(item.credit_no, brands, "brands") - 1,
  patentRisks:
    getlength(item.credit_no, patents, "patents") - 2 < 0
      ? 0
      : getlength(item.credit_no, patents, "patents") - 2,
}));

export default function Index() {
  return (
    <>
      <PageHeader
        title="监测预警"
        description="针对入驻园区企业的经营状态，商标、专利等知识产权情况进行检测，并提供预警信息。"
      />
      <div className={styles.timestamp}>
        最后更新时间：2023年6月12日 23:00:00
      </div>
      <div className={styles.actions}>
        <Form layout="inline">
          <Form.Item name="keywords">
            <Input
              style={{ width: "16rem" }}
              placeholder="企业名称/统一社会信用代码"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              检索
            </Button>
          </Form.Item>
        </Form>
        <div>
          <Button type="primary" icon={<DownloadOutlined />}>
            导出报表
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        <Table
          columns={[
            {
              title: "企业名称",
              dataIndex: "name",
              render: (data, record) => (
                // <Link
                //   to={`/1/monitoring/1`}
                //   state={{ sn: "91110108551385082Q" }}
                // >
                //   {data}
                // </Link>
                <Link to={`/1/monitoring/${record.id}`} state={record}>
                  {data}
                </Link>
              ),
            },
            {
              title: "统一社会信用代码",
              dataIndex: "sn",
            },
            {
              title: "所属行业",
              dataIndex: "industry",
            },
            {
              title: "经营风险",
              dataIndex: "risks",
              render: (data) => (
                <Typography.Text type="danger" strong>
                  {data}
                </Typography.Text>
              ),
            },
            {
              title: "商标预警",
              dataIndex: "trademarkRisks",
              render: (data) => (
                <Typography.Text type="danger" strong>
                  {data}
                </Typography.Text>
              ),
            },
            {
              title: "专利预警",
              dataIndex: "patentRisks",
              render: (data) => (
                <Typography.Text type="danger" strong>
                  {data}
                </Typography.Text>
              ),
            },
          ]}
          dataSource={mockDataSource}
          rowKey="id"
        />
      </div>
    </>
  );
}
