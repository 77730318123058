import { useState } from 'react';
import { Space, Tag, Input } from 'antd';
import styles from './Tags.module.css';
import { PlusOutlined } from '@ant-design/icons';

export default function Tags() {
  const [ addable, setAddable ] = useState(false);

  return (
    <div className={styles.root}>
      <Space>
        <Tag color="green" closable>规上企业</Tag>
        <Tag color="green" closable>示范试点</Tag>
        { 
          !addable ? 
          <Tag className={styles.add} onClick={() => setAddable(true)}><PlusOutlined /> 添加标签</Tag> :
          <Input
            autoFocus
            onBlur={() => setAddable(false)}
            onPressEnter={() => setAddable(false)}
            className={styles.input}
            size="small"
            placeholder="标签"
          />
        }        
      </Space>
    </div>
  );
}