import { useState } from 'react';
import {
  Row,
  Col,
  Anchor,
  Typography,
  Descriptions,
  Tag,
  Avatar,
  List,
  Button,
} from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  NumberOutlined,
  PaperClipOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import PageHeader from "../../components/PageHeader";
import CreateModal from './Create.Modal';
import styles from "./consulting.module.css";

export default function Details() {
  const location = useLocation();
  const { state } = location;
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  return (
    <>
      <PageHeader
        title="商标注册业务咨询"
        description="查阅来自企业品牌相关的咨询内容，第一时间了解企业需求，向企业输出专业的品牌服务。"
        breadcrumb={[
          { title: <Link to="/1/consulting">监测预警</Link> },
          { title: "商标注册业务咨询" },
        ]}
      />
      <Row gutter={32}>
        <Col span={18}>
          <Typography.Title className={styles.title} id="基本信息" level={5}>
            <NumberOutlined />
            基本信息
          </Typography.Title>
          <Descriptions
            column={2}
            className={styles.descriptions}
            labelStyle={{ whiteSpace: "nowrap" }}
            bordered
          >
            <Descriptions.Item label="咨询单号">{state.id}</Descriptions.Item>
            <Descriptions.Item label="咨询业务名称">
              <Avatar
                style={{ marginRight: ".5rem", backgroundColor: "#333333" }}
              >
                S
              </Avatar>
              {state.name}
            </Descriptions.Item>
            <Descriptions.Item label="服务状态">
              <Tag color="#97CE74">待受理</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="咨询时间">
              {state.createdAt}
            </Descriptions.Item>
            <Descriptions.Item label="最后更新时间">
              {state.updatedAt}
            </Descriptions.Item>
          </Descriptions>

          <Typography.Title className={styles.title} id="咨询人" level={5}>
            <NumberOutlined />
            {state.client}
          </Typography.Title>
          <Descriptions
            column={2}
            className={styles.descriptions}
            labelStyle={{ whiteSpace: "nowrap" }}
            bordered
          >
            <Descriptions.Item label="咨询人">{state.client}</Descriptions.Item>
            <Descriptions.Item label="手机">{state.mobile}</Descriptions.Item>
            <Descriptions.Item label="咨询人职务">技术总监</Descriptions.Item>
            <Descriptions.Item label="咨询人公司">
              {state.company}
            </Descriptions.Item>
          </Descriptions>

          <div className={styles.toolbar}>
            <Typography.Title className={styles.title} id="沟通记录" level={5}>
              <NumberOutlined />
              沟通记录
            </Typography.Title>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalOpen(true)}>添加记录</Button>
            <CreateModal
                open={isModalOpen}
                onOk={ () => setIsModalOpen(false) }
                onCancel={ () => setIsModalOpen(false) }
              />
          </div>
          <div className={styles.section}>
            <List
              itemLayout="vertical"
              size="large"
              dataSource={[
                {
                  id: 1,
                  name: "郁凯",
                  company: "成都信息智能科技",
                  content:
                    "想要了解一下关于我司商标注册的一些问题……你看是否可以约时间电话沟通一下？",
                  createdAt: "今天 15:23",
                },
                {
                  id: 2,
                  name: "客服",
                  company: "环球互通品牌服务有限公司",
                  content:
                    "当然可以，明天（6月20日）上午10:00如何？你可以先看一下这些材料，到时可以一起讨论下。",
                  createdAt: "今天 16:04",
                  attachments: [
                    { id: 1, title: "商标注册准备材料.xlsx", href: "#" },
                    { id: 2, title: "商标注册委托书.doc", href: "#" },
                  ],
                },
              ]}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  extra={
                    <Typography.Text type="secondary">
                      {item.createdAt}
                    </Typography.Text>
                  }
                >
                  <List.Item.Meta
                    avatar={<Avatar size="large">{item.name}</Avatar>}
                    description={item.company}
                    title={item.name}
                  />
                  <div>{item.content}</div>
                  {item.attachments && (
                    <div className={styles.attachments}>
                      {item.attachments.map((item) => (
                        <Link key={item.id} to={item.href}>
                          <PaperClipOutlined
                            style={{ marginRight: ".25rem" }}
                          />
                          {item.title}
                        </Link>
                      ))}
                    </div>
                  )}
                </List.Item>
              )}
            />
          </div>
          <Typography.Title className={styles.title} id="受理记录" level={5}>
            <NumberOutlined />
            受理记录
          </Typography.Title>
          <div className={styles.section}>
            <List
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta description={item.tiemstamp} />
                  <div>{item.title}</div>
                </List.Item>
              )}
              dataSource={[
                {
                  tiemstamp: "2023年6月13日 14:01",
                  title: "提交申请",
                },
                {
                  tiemstamp: "2023年6月13日 14:39",
                  title: "已受理",
                },
              ]}
            />
          </div>
        </Col>
        <Col span={6}>
          <Anchor
            offsetTop={96}
            items={[
              { key: "1", href: "#基本信息", title: "基本信息" },
              { key: "2", href: "#咨询人", title: "咨询人" },
              { key: "3", href: "#沟通记录", title: "沟通记录" },
              { key: "4", href: "#受理记录", title: "受理记录" },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}
