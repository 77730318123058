import { Form, Input, Modal, Upload, Row, Col } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import styles from './Create.Modal.module.css';

export default function CreateModal({ open, onOk, onCancel }) {
  return (
    <Modal
      title="添加沟通记录"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      width={720}
    >
      <Row gutter={32} className={styles.root}>
        <Col span={24}>
          {/* <Alert className={styles.alert} type="info" message="请填写联系方式，咨询顾问将很快与其联系。" showIcon /> */}
          <Form layout="vertical">
            <Form.Item label="内容" name="content">
              <Input.TextArea placeholder="" rows={6} /> 
            </Form.Item>
            <Form.Item label="上传附件">
              <Upload.Dragger>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">可以点击或拖拽文件来上传</p>
                <p className="ant-upload-hint">
                  可以同时上传多个文件，文件类型支持图片、Office文档等
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}