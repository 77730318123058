import { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import { palette1 } from './palette';

export default function Chart1() {
  const element = useRef();
  useEffect(() => {
    const chart = new Chart(element.current, {
      type: 'pie',
      data: {
        labels: [ '批发和零售', '租赁和商业服务', '住宿和餐饮', '科学研究和技术服务', '信息技术软件服务'],
        datasets: [
          {
            data: [ 9, 15, 28, 2, 16 ],
            backgroundColor: palette1
          }
        ]
      },
      options: {
        aspectRatio: 1.5,
        layout: {
          // padding: 16
        },
        plugins: {
          legend: { position: 'right' }
        }
      }
    });
    return () => chart.destroy();
  }, []);
  return (
    <canvas ref={element} />
  );
}