import { Form, Input, Button, Table, Typography, Tag, Select, Space } from "antd";
import { Link } from "react-router-dom";
import { random } from "lodash";
import { ClearOutlined, DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "./query.module.css";
import PageHeader from "../../components/PageHeader";

import companies from "../../data.js/companies";
import brands from "../../data.js/brands";
import patents from "../../data.js/patents";
import copyrights from "../../data.js/copyrights";

// import company from "../../data.js/company";
const getlength = (sn, category, prop) => {
  const result = category.find((item) => {
    return item.creditNo === sn;
  });

  return result[prop].length;
  // console.log("result :>> ", result[prop]);
};

const mockDataSource = companies?.map((item, index) => ({
  id: index + 1,
  name: item.name,
  sn: item.credit_no,
  industry: "信息技术服务",
  risks: getlength(item.credit_no, brands, "brands") + 3,
  isHighTech: getlength(item.credit_no, patents, "patents") > 5,
  isHonored: getlength(item.credit_no, brands, "brands") > 3,
  trademarks: getlength(item.credit_no, brands, "brands"),
  patents: getlength(item.credit_no, patents, "patents"),
  copyrights: getlength(item.credit_no, copyrights, "copyrights"),
  tags: random(20) > 18 ? ["重点企业"] : [],
}));

export default function Companies() {
  return (
    <>
      <PageHeader
        title="企业数据检索"
        description="检索入驻园区企业的各类相关数据，包括工商注册信息、企业资质、经营异常信息等。"
      />
      <div className={styles.timestamp}>
        最后更新时间：2023年6月12日 23:00:00
      </div>
      <div className={styles.actions}>
        <Form layout="inline">
          <Form.Item name="keywords">
            <Input style={{ width: "16rem" }} placeholder="企业名称/统一社会信用代码" />
          </Form.Item>
          <Form.Item name="industory">
            <Select placeholder="所属行业" />
          </Form.Item>
          <Form.Item name="highTech">
            <Select placeholder="科技型企业类型" />
          </Form.Item>
          <Form.Item name="tags">
            <Select placeholder="标签" />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>检索</Button>
              <Button type="primary" htmlType="reset" icon={<ClearOutlined />} ghost>清空</Button>
            </Space>
          </Form.Item>
        </Form>
        <div>
          <Space>
            <Button type="primary" icon={<DownloadOutlined />}>导出报表</Button>
          </Space>
        </div>
      </div>
      <div className={styles.section}>
        <Table
          columns={[
            {
              title: "企业名称",
              dataIndex: "name",
              render: (data, record) => (
                <Link
                  to={{
                    pathname: `/1/query/companies/${record.id}`,
                    // pathname: `/1/query/companies/1`,
                  }}
                  state={{ sn: record.sn, risks: record.risks }}
                  // state={{ sn: "91110108551385082Q" }}
                >
                  {data}
                </Link>
              ),
            },
            {
              title: "统一社会信用代码",
              dataIndex: "sn",
            },
            {
              title: "所属行业",
              dataIndex: "industry",
            },
            {
              title: "经营风险",
              dataIndex: "risks",
              render: (data) =>
                data > 0 ? (
                  <Typography.Text type="danger" strong>
                    {data}
                  </Typography.Text>
                ) : (
                  ""
                ),
            },
            {
              title: "高新企业",
              dataIndex: "isHighTech",
              render: (data) =>
                data ? <Tag color="#1B9C85">高新企业</Tag> : "",
            },
            {
              title: "老字号",
              dataIndex: "isHonored",
              render: (data) => (data ? <Tag color="#1B9C85">老字号</Tag> : ""),
            },
            {
              title: "商标",
              dataIndex: "trademarks",
            },
            {
              title: "专利",
              dataIndex: "patents",
            },
            {
              title: "著作权",
              dataIndex: "copyrights",
            },
            {
              title: "标签",
              dataIndex: "tags",
              render: (data) =>
                data.map((item) => (
                  <Tag color="#2db7f5" key={item}>
                    {item}
                  </Tag>
                )),
            },
          ]}
          dataSource={mockDataSource}
          rowKey="id"
        />
      </div>
    </>
  );
}
