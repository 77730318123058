import { Form, Input, Button, Table, Tag, Statistic, Avatar } from "antd";
import { Link } from "react-router-dom";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "./consulting.module.css";
import { range, random, gt } from "lodash";
import PageHeader from "../../components/PageHeader";

function generateRandomChineseCompanyName() {
  const prefix = [
    "上海",
    "深圳",
    "北京",
    "广州",
    "南京",
    "苏州",
    "杭州",
    "成都",
    "重庆",
  ];
  const middle = [
    "科技",
    "信息",
    "互联",
    "智能",
    "网络",
    "数字",
    "金融",
    "电子",
    "软件",
    "物联",
  ];
  const suffix = [
    "有限公司",
    "集团",
    "科技公司",
    "信息科技",
    "电子商务",
    "软件开发",
    "网络技术",
    "智能科技",
  ];

  const randomPrefixIndex = Math.floor(Math.random() * prefix.length);
  const randomMiddleIndex = Math.floor(Math.random() * middle.length);
  const randomSuffixIndex = Math.floor(Math.random() * suffix.length);

  const companyName =
    prefix[randomPrefixIndex] +
    middle[randomMiddleIndex] +
    suffix[randomSuffixIndex];

  return companyName;
}

function generateRandomPhoneNumber() {
  let phoneNumber = "1"; // 手机号码第一位为1

  for (let i = 0; i < 10; i++) {
    phoneNumber += Math.floor(Math.random() * 10); // 生成0-9之间的随机数字并拼接至手机号码后面
  }

  return phoneNumber;
}
function generateRandomChineseName() {
  const surnames = ["张", "王", "李", "赵"];
  const names = [
    "婷",
    "瑞",
    "莉",
    "洁",
    "静",
    "秀",
    "爽",
    "皓",
    "明",
    "丽",
    "伟",
    "珊",
    "宝",
    "勇",
  ];

  const randomSurnameIndex = Math.floor(Math.random() * surnames.length);
  const randomNameIndex = Math.floor(Math.random() * names.length);

  const surname = surnames[randomSurnameIndex];
  const name = names[randomNameIndex];

  return surname + name;
}

const mockDataSource = range(50).map((item) => ({
  id: `HQHT0000${random(9999)}`,
  name: ["商标注册", "专利申请", "著作权登记"][random(2)],
  client: generateRandomChineseName(),
  company: generateRandomChineseCompanyName(),
  mobile: generateRandomPhoneNumber(),
  status: 0,
  createdAt: "2023年6月13日 14:00:00",
  updatedAt: "2023年6月13日 14:00:00",
}));

export default function Index() {
  return (
    <>
      <PageHeader
        title="咨询服务"
        description="检索来自园区入驻企业的相关咨询请求。"
      />
      <div className={styles.section}>
        <div className={styles.statistic}>
          <div className={styles.item}>
            <Statistic title="待受理" value={433} />
          </div>
          <div className={styles.divider} />
          <div className={styles.item}>
            <Statistic title="受理中" value={27} />
          </div>
          <div className={styles.divider} />
          <div className={styles.item}>
            <Statistic title="已完成" value={0} />
          </div>
          <div className={styles.divider} />
          <div className={styles.item}>
            <Statistic title="已取消" value={3} />
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <Form layout="inline">
          <Form.Item name="keywords">
            <Input style={{ width: "16rem" }} placeholder="业务名称 / 订单号" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              检索
            </Button>
          </Form.Item>
        </Form>
        <div>
          <Button type="primary" icon={<DownloadOutlined />}>
            导出报表
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        <Table
          columns={[
            {
              title: "咨询单号",
              dataIndex: "id",
              render: (data, record) => (
                <Link to={`/1/consulting/${record.id}`} state={record}>
                  {data}
                </Link>
              ),
              sorter: (a, b) => (gt(a, b) ? 1 : -1),
            },
            {
              title: "业务名称",
              dataIndex: "name",
              render: (data) => (
                <div>
                  <Avatar
                    style={{ marginRight: ".5rem", backgroundColor: "#333333" }}
                  >
                    S
                  </Avatar>
                  {data}
                </div>
              ),
              sorter: (a, b) => (gt(a, b) ? 1 : -1),
            },
            {
              title: "咨询人",
              dataIndex: "client",
            },
            {
              title: "手机",
              dataIndex: "mobile",
            },
            {
              title: "公司",
              dataIndex: "company",
            },
            {
              title: "服务状态",
              dataIndex: "status",
              render: (data) => <Tag color="#87d068">待受理</Tag>,
            },
            {
              title: "咨询时间",
              dataIndex: "createdAt",
            },
            {
              title: "最后更新时间",
              dataIndex: "updatedAt",
            },
          ]}
          dataSource={mockDataSource}
          rowKey="id"
        />
      </div>
    </>
  );
}
