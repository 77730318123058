import { Row, Col, Statistic, Button } from "antd";
import styles from "./kanban.module.css";
import {
  Chart7,
  Chart8,
  Chart9,
  Chart10,
  Chart11,
  Chart19,
} from "../../components/charts";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";

const span = { xs: 24, sm: 24, md: 24, lg: 12, xl: 8, xxl: 8 };

export default function Kanban1() {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader
        title="商标数据统计"
        description="针对入驻园区企业的商标、驰名商标、海关备案等数据的统计看板。"
      />
      <div className={styles.timestamp}>
        最后更新时间：2023年6月12日 23:00:00
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className={styles.section}>
            <div className={styles.statistic}>
              <div className={styles.item}>
                <Statistic title="商标注册数量" value={7499} />
                <Button
                  type="primary"
                  onClick={() => navigate("/1/query/trademarks")}
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="驰名商标数量" value={8} />
                <Button
                  type="primary"
                  onClick={() => navigate("/1/query/trademarks")}
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="马德里商标数量" value={0} />
                <Button
                  type="primary"
                  onClick={() => navigate("/1/query/trademarks")}
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="海关备案数量" value={0} />
                <Button
                  type="primary"
                  onClick={() => navigate("/1/query/trademarks")}
                >
                  查看详情
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>商标注册分类（前5类）</h2>
              <Chart7 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>驰名商标注册分类（前5类）</h2>
              <Chart8 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>马德里商标注册数量（近5年）</h2>
              <Chart19 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>海关备案数量（近5年）</h2>
              <Chart9 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>商标申请/注册数量（近5年）</h2>
              <Chart10 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>驰名商标数量（近5年）</h2>
              <Chart11 />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
