const industries = [
  {
    creditNo: "91110108551385082Q",
    level: "3",
    industry_code: "M7590",
    industry_name: "其他科技推广服务业",
  },
  {
    creditNo: "914403001922038216",
    level: "3",
    industry_code: "C3920",
    industry_name: "通信设备制造",
  },
  {
    creditNo: "91330201764518889H",
    level: "4",
    industry_code: "C1819",
    industry_name: "其他机织服装制造",
  },
  {
    creditNo: "91350500768588454G",
    level: "4",
    industry_code: "C3551",
    industry_name: "纺织专用设备制造",
  },
  {
    creditNo: "91110108558521630L",
    level: "2",
    industry_code: "F5100",
    industry_name: "批发业",
  },
  {
    creditNo: "914420006947806297",
    level: "3",
    industry_code: "C3850",
    industry_name: "家用电力器具制造",
  },
  {
    creditNo: "91110302587727910P",
    level: "4",
    industry_code: "C3911",
    industry_name: "计算机整机制造",
  },
  {
    creditNo: "91110400MA04ENG447",
    level: "4",
    industry_code: "C3612",
    industry_name: "新能源车整车制造",
  },
  {
    creditNo: "91330103580267747M",
    level: "2",
    industry_code: "M7500",
    industry_name: "科技推广和应用服务业",
  },
];
export default industries;
