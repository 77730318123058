const allcopyrights = [
  {
    type_num: "-",
    related_companies: [],
    id: "5def6c45ebf5513f130086d8",
    first_date: "-",
    name: "深林生态负离子",
    created_time: "2019-12-10",
    version: "-",
    type_name: "美术",
    approval_date: "2012-06-18",
    short_name: "-",
    company: "苹果（中国）有限公司",
    last_updated_time: "2019-12-10",
    type: "P",
    number: "浙作登字-2012-F-00007359",
    success_date: "2012-06-04",
  },
  {
    type_num: "-",
    related_companies: [],
    id: "6470635347402081d2f313de",
    first_date: "2023-03-31",
    name: "华为培训评估体系VALUE模型",
    created_time: "2023-05-26",
    version: "-",
    type_name: "地图、示意图",
    approval_date: "2023-05-23",
    short_name: "-",
    company: "华为技术有限公司",
    last_updated_time: "1970-01-01",
    type: "P",
    number: "国作登字-2023-K-00097242",
    success_date: "2023-03-31",
  },
  {
    type_num: "-",
    related_companies: [],
    id: "643e495556e314cc8f465797",
    first_date: "2023-01-16",
    name: "华为技术培训：5GtoB产品销售训战项目学习旅程",
    created_time: "2023-04-18",
    version: "-",
    type_name: "地图、示意图",
    approval_date: "2023-04-13",
    short_name: "-",
    company: "华为技术有限公司",
    last_updated_time: "1970-01-01",
    type: "P",
    number: "国作登字-2023-K-00064443",
    success_date: "2023-01-16",
  },
  {
    type_num: "-",
    related_companies: [],
    id: "643227b756e314cc8f4524d3",
    first_date: "-",
    name: "小米环形冷泵（英文名称：Mi IceLoop）",
    created_time: "2023-04-09",
    version: "-",
    type_name: "美术",
    approval_date: "2023-03-01",
    short_name: "-",
    company: "小米科技有限责任公司",
    last_updated_time: "1970-01-01",
    type: "P",
    number: "国作登字-2023-F-00039251",
    success_date: "2022-11-02",
  },
  {
    type_num: "-",
    related_companies: [],
    id: "6374520836559c1464e3ffa8",
    first_date: "2022-10-13",
    name: "华为云网融合认证框架",
    created_time: "2022-11-16",
    version: "-",
    type_name: "其他",
    approval_date: "2022-11-09",
    short_name: "-",
    company: "华为技术有限公司",
    last_updated_time: "1970-01-01",
    type: "P",
    number: "国作登字-2022-L-10232781",
    success_date: "2022-10-13",
  },
  {
    type_num: "-",
    related_companies: [],
    id: "6374520836559c1464e3ffa7",
    first_date: "2022-10-13",
    name: "华为5G认证框架",
    created_time: "2022-11-16",
    version: "-",
    type_name: "其他",
    approval_date: "2022-11-09",
    short_name: "-",
    company: "华为技术有限公司",
    last_updated_time: "1970-01-01",
    type: "P",
    number: "国作登字-2022-L-10232784",
    success_date: "2022-10-13",
  },
  {
    type_num: "-",
    related_companies: [],
    id: "6196691de94893f7d4454340",
    first_date: "-",
    name: "GAMIOCENE LOGO",
    created_time: "2021-11-18",
    version: "-",
    type_name: "美术",
    approval_date: "2021-10-26",
    short_name: "-",
    company: "小米科技有限责任公司",
    last_updated_time: "1970-01-01",
    type: "P",
    number: "国作登字-2021-F-00245825",
    success_date: "2021-07-22",
  },
  {
    type_num: "-",
    related_companies: [],
    id: "60ba764a777b71b4d7bb5efa",
    first_date: "-",
    name: "小米品牌标识",
    created_time: "2021-06-05",
    version: "-",
    type_name: "美术",
    approval_date: "2021-05-24",
    short_name: "-",
    company: "小米科技有限责任公司",
    last_updated_time: "1970-01-01",
    type: "P",
    number: "国作登字-2021-F-00112980",
    success_date: "2021-02-24",
  },
  {
    type_num: "-",
    related_companies: [],
    id: "60ba76393c089dfce5786d34",
    first_date: "-",
    name: "xiaomi字母Logo",
    created_time: "2021-06-05",
    version: "-",
    type_name: "美术",
    approval_date: "2021-05-24",
    short_name: "-",
    company: "小米科技有限责任公司",
    last_updated_time: "1970-01-01",
    type: "P",
    number: "国作登字-2021-F-00112979",
    success_date: "2021-03-05",
  },
];
export default allcopyrights;
