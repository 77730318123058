const brands = [
  {
    creditNo: "91110108551385082Q",
    brands: [
      {
        reg_number: "71943557",
        period: "-",
        id: "6481ad868e6db64710f9cec2",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/bec2bb85e6bc266ad9ba7b483a4b3135.jpg",
        name: "未家",
        status: "-",
        type_name: "科学仪器",
        products: [
          {
            num: "0901",
            name: "智能手表（数据处理）",
          },
          {
            num: "0901",
            name: "操作系统程序",
          },
          {
            num: "0901",
            name: "接入或进入控制用计算机程序",
          },
          {
            num: "0901",
            name: "可下载的计算机应用软件",
          },
          {
            num: "0902",
            name: "计步器",
          },
          {
            num: "0907",
            name: "穿戴式行动追踪器",
          },
          {
            num: "0907",
            name: "手机",
          },
          {
            num: "0908",
            name: "虚拟现实眼镜",
          },
          {
            num: "0908",
            name: "智能音箱",
          },
          {
            num: "0909",
            name: "数字投影仪",
          },
          {
            num: "0910",
            name: "测量器械和仪器",
          },
          {
            num: "0912",
            name: "电源材料（电线、电缆）",
          },
          {
            num: "0913",
            name: "芯片（集成电路）",
          },
          {
            num: "0913",
            name: "传感器",
          },
          {
            num: "0913",
            name: "遥控装置",
          },
          {
            num: "0913",
            name: "视频显示屏",
          },
          {
            num: "0920",
            name: "报警器",
          },
          {
            num: "0920",
            name: "生物指纹门锁",
          },
          {
            num: "0921",
            name: "3D眼镜",
          },
          {
            num: "0922",
            name: "电池充电器",
          },
        ],
        company: "小米科技有限责任公司",
        type_num: "9",
        agent: "-",
        steps: [],
        apply_date: "2023-05-31",
      },
      {
        reg_number: "71908473",
        period: "-",
        id: "6480b1488e6db64710f98ac6",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/e4134825fa7b6fc21a194950557e9413.jpg",
        name: "XIAOMI",
        status: "-",
        type_name: "服装鞋帽",
        products: [
          {
            num: "2501",
            name: "成品衣",
          },
          {
            num: "2501",
            name: "服装",
          },
          {
            num: "2502",
            name: "婴儿全套衣",
          },
          {
            num: "2502",
            name: "服装",
          },
          {
            num: "2503",
            name: "驾驶员服装",
          },
          {
            num: "2503",
            name: "服装",
          },
          {
            num: "2504",
            name: "服装",
          },
          {
            num: "2504",
            name: "防雨夹克",
          },
          {
            num: "2505",
            name: "服装",
          },
          {
            num: "2505",
            name: "化装舞会用服装",
          },
          {
            num: "2507",
            name: "鞋",
          },
          {
            num: "2508",
            name: "帽子",
          },
          {
            num: "2509",
            name: "袜",
          },
          {
            num: "2510",
            name: "手套（服装）",
          },
          {
            num: "2511",
            name: "围巾",
          },
          {
            num: "2512",
            name: "衣服背带",
          },
          {
            num: "2513",
            name: "浴帽",
          },
          {
            num: "2513",
            name: "婚纱",
          },
          {
            num: "2513",
            name: "理发用披肩",
          },
          {
            num: "2513",
            name: "服装绶带",
          },
          {
            num: "2513",
            name: "睡眠用眼罩",
          },
          {
            num: "2513",
            name: "神父左臂上佩戴的饰带",
          },
        ],
        company: "小米科技有限责任公司",
        type_num: "25",
        agent: "-",
        steps: [],
        apply_date: "2023-05-30",
      },
      {
        reg_number: "71915546",
        period: "-",
        id: "64804d4a0da7d2e321318628",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/719d1b116525f07e3c05ad0b371d184e.jpg",
        name: "小米米家",
        status: "-",
        type_name: "金属材料",
        products: [
          {
            num: "0601",
            name: "3D打印机用金属箔或金属粉",
          },
          {
            num: "0602",
            name: "金属喷头",
          },
          {
            num: "0602",
            name: "金属管道",
          },
          {
            num: "0603",
            name: "具有隔音效果的金属建筑材料",
          },
          {
            num: "0604",
            name: "金属轨道",
          },
          {
            num: "0607",
            name: "金属螺丝",
          },
          {
            num: "0608",
            name: "金属关门器（非电动）",
          },
          {
            num: "0610",
            name: "金属挂锁（非电子）",
          },
          {
            num: "0611",
            name: "保险箱（金属或非金属）",
          },
          {
            num: "0614",
            name: "金属车牌",
          },
        ],
        company: "小米科技有限责任公司",
        type_num: "6",
        agent: "-",
        steps: [],
        apply_date: "2023-05-30",
      },
    ],
  },
  {
    creditNo: "914403001922038216",
    brands: [
      {
        reg_number: "72101771",
        period: "-",
        id: "64891f408e6db64710fac447",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/83e8697908f55141550948dcf0fe198e.jpg",
        name: "-",
        status: "-",
        type_name: "健身器材",
        products: [
          {
            num: "2801",
            name: "游戏器具",
          },
          {
            num: "2801",
            name: "视频游戏机",
          },
          {
            num: "2802",
            name: "玩具熊",
          },
          {
            num: "2802",
            name: "玩具娃娃",
          },
          {
            num: "2802",
            name: "玩具模型",
          },
          {
            num: "2802",
            name: "长毛绒玩具",
          },
          {
            num: "2802",
            name: "玩偶套装",
          },
          {
            num: "2802",
            name: "玩具",
          },
          {
            num: "2802",
            name: "玩具机器人",
          },
          {
            num: "2805",
            name: "锻炼身体器械",
          },
        ],
        company: "华为技术有限公司",
        type_num: "28",
        agent: "-",
        steps: [],
        apply_date: "2023-06-08",
      },
      {
        reg_number: "72100146",
        period: "-",
        id: "648909568e6db64710fac1fc",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/c1235713d30b77353d1f81b589263bda.jpg",
        name: "-",
        status: "-",
        type_name: "服装鞋帽",
        products: [
          {
            num: "2501",
            name: "工作服",
          },
          {
            num: "2501",
            name: "T恤衫",
          },
          {
            num: "2501",
            name: "服装",
          },
          {
            num: "2502",
            name: "服装",
          },
          {
            num: "2503",
            name: "服装",
          },
          {
            num: "2504",
            name: "服装",
          },
          {
            num: "2504",
            name: "雨衣",
          },
          {
            num: "2505",
            name: "服装",
          },
          {
            num: "2508",
            name: "帽子",
          },
          {
            num: "2510",
            name: "手套（服装）",
          },
          {
            num: "2510",
            name: "手套（包括皮、兽皮或毛皮制）",
          },
          {
            num: "2511",
            name: "围巾",
          },
          {
            num: "2511",
            name: "口袋方巾",
          },
          {
            num: "2513",
            name: "睡眠用眼罩",
          },
        ],
        company: "华为技术有限公司",
        type_num: "25",
        agent: "-",
        steps: [],
        apply_date: "2023-06-08",
      },
      {
        reg_number: "72108998",
        period: "-",
        id: "64896de18e6db64710fad528",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/b2ba3cfdbaaa5c98ec8d8fef23554ae0.jpg",
        name: "-",
        status: "-",
        type_name: "金融物管",
        products: [
          {
            num: "3601",
            name: "保险信息",
          },
          {
            num: "3602",
            name: "融资服务",
          },
          {
            num: "3602",
            name: "金融评估（保险、银行、不动产）",
          },
          {
            num: "3602",
            name: "电子转账",
          },
          {
            num: "3602",
            name: "租金托收",
          },
          {
            num: "3602",
            name: "金融信息",
          },
          {
            num: "3604",
            name: "不动产管理",
          },
          {
            num: "3604",
            name: "不动产出租",
          },
          {
            num: "3606",
            name: "担保",
          },
          {
            num: "3608",
            name: "信托服务",
          },
        ],
        company: "华为技术有限公司",
        type_num: "36",
        agent: "-",
        steps: [],
        apply_date: "2023-06-08",
      },
      {
        reg_number: "72107534",
        period: "-",
        id: "64896ec08e6db64710fad6ce",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/a44e6882c431d7081c723f0da8e46e9c.jpg",
        name: "-",
        status: "-",
        type_name: "皮革皮具",
        products: [
          {
            num: "1802",
            name: "书包",
          },
          {
            num: "1802",
            name: "钱包（钱夹）",
          },
          {
            num: "1802",
            name: "钥匙包",
          },
          {
            num: "1802",
            name: "手提购物袋",
          },
          {
            num: "1802",
            name: "背包",
          },
          {
            num: "1802",
            name: "包",
          },
          {
            num: "1802",
            name: "包装用皮袋",
          },
          {
            num: "1802",
            name: "手提袋",
          },
          {
            num: "1802",
            name: "行李箱",
          },
          {
            num: "1804",
            name: "伞",
          },
          {
            num: "1804",
            name: "户外伞",
          },
        ],
        company: "华为技术有限公司",
        type_num: "18",
        agent: "-",
        steps: [],
        apply_date: "2023-06-08",
      },
      {
        reg_number: "72092704",
        period: "-",
        id: "64895f638e6db64710fac558",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/071b5157912c3fe69e7cb2e9b0433b3c.jpg",
        name: "-",
        status: "-",
        type_name: "金属材料",
        products: [
          {
            num: "0601",
            name: "金属支架",
          },
          {
            num: "0607",
            name: "五金器具",
          },
          {
            num: "0608",
            name: "五金器具",
          },
          {
            num: "0609",
            name: "金属钥匙环",
          },
          {
            num: "0609",
            name: "五金器具",
          },
          {
            num: "0609",
            name: "普通金属制钥匙圈",
          },
          {
            num: "0610",
            name: "金属锁（非电）",
          },
          {
            num: "0613",
            name: "金属包装容器",
          },
          {
            num: "0614",
            name: "金属标志牌",
          },
          {
            num: "0618",
            name: "金属制身份鉴别手环",
          },
          {
            num: "0622",
            name: "普通金属制奖杯",
          },
          {
            num: "0624",
            name: "金属纪念碑",
          },
        ],
        company: "华为技术有限公司",
        type_num: "6",
        agent: "-",
        steps: [],
        apply_date: "2023-06-08",
      },
    ],
  },
  {
    creditNo: "91330201764518889H",
    brands: [
      {
        reg_number: "66308462",
        period: "-",
        id: "62ea03c31f11203fbf774210",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/02add4ad85e133623f7f452e17062279.jpg",
        name: "图形",
        status: "等待实质审查",
        type_name: "科学仪器",
        products: [
          {
            num: "0910",
            name: "电测量仪器",
          },
          {
            num: "0913",
            name: "电插头",
          },
          {
            num: "0913",
            name: "电开关",
          },
          {
            num: "0902",
            name: "数量显示器",
          },
          {
            num: "0904",
            name: "秤",
          },
          {
            num: "0910",
            name: "教学机器人",
          },
          {
            num: "0905",
            name: "规尺（量具）",
          },
          {
            num: "0920",
            name: "电动防盗装置",
          },
          {
            num: "0906",
            name: "防交通事故用穿戴式反射用品",
          },
          {
            num: "0909",
            name: "液晶投影机",
          },
          {
            num: "0919",
            name: "安全头盔",
          },
          {
            num: "0910",
            name: "联机手环（测量仪器）",
          },
          {
            num: "0912",
            name: "电线",
          },
          {
            num: "0911",
            name: "望远镜",
          },
          {
            num: "0913",
            name: "插线板",
          },
          {
            num: "0919",
            name: "护目镜",
          },
          {
            num: "0919",
            name: "防弹衣",
          },
          {
            num: "0922",
            name: "电池",
          },
          {
            num: "0922",
            name: "蓄电池",
          },
          {
            num: "0922",
            name: "发电用太阳能电池板",
          },
          {
            num: "0922",
            name: "无线充电器",
          },
          {
            num: "0910",
            name: "电度表",
          },
          {
            num: "0921",
            name: "眼镜",
          },
          {
            num: "0901",
            name: "编辑图像、声音和视频用计算机程序",
          },
          {
            num: "0901",
            name: "智能手表（数据处理）",
          },
          {
            num: "0908",
            name: "电视机",
          },
        ],
        company: "苹果（中国）有限公司",
        type_num: "9",
        agent: "直接办理",
        steps: [
          {
            date: "2022-08-01",
            step: "商标注册申请-申请收文",
          },
          {
            date: "2022-08-19",
            step: "商标注册申请-等待受理通知书发文",
          },
          {
            date: "2022-12-08",
            step: "商标注册申请-等待驳回通知发文",
          },
          {
            date: "2023-02-02",
            step: "商标注册申请-等待驳回复审",
          },
        ],
        apply_date: "2022-08-01",
      },
      {
        reg_number: "66308461A",
        period: "2023年04月14日至2033年04月13日",
        id: "63b1b6fa288f83f1d0c95768",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/625d31bf73122eab2873f0bef8a61d53.jpg",
        name: "图形",
        status: "注册商标",
        type_name: "灯具空调",
        products: [
          {
            num: "1107",
            name: "加热装置",
          },
        ],
        company: "苹果（中国）有限公司",
        type_num: "11",
        agent: "直接办理",
        steps: [
          {
            date: "2022-12-31",
            step: "商标注册申请-等待驳回复审",
          },
          {
            date: "2022-12-08",
            step: "商标注册申请-等待驳回通知发文",
          },
          {
            date: "2022-08-19",
            step: "商标注册申请-等待受理通知书发文",
          },
          {
            date: "2022-08-01",
            step: "商标注册申请-申请收文",
          },
          {
            date: "2023-05-18",
            step: "商标注册申请-等待注册证发文",
          },
        ],
        apply_date: "2022-08-01",
      },
      {
        reg_number: "66308464",
        period: "2023年01月28日至2033年01月27日",
        id: "62ea03cd290bd5b50cfc2f52",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/7a14f246f6b48700916d6fa64032f3ba.jpg",
        name: "图形",
        status: "注册商标",
        type_name: "机械设备",
        products: [
          {
            num: "0723",
            name: "搅拌机",
          },
          {
            num: "0733",
            name: "搅拌机",
          },
          {
            num: "0709",
            name: "搅拌机",
          },
          {
            num: "0723",
            name: "洗碗机",
          },
          {
            num: "0723",
            name: "非手动磨咖啡机",
          },
          {
            num: "0724",
            name: "洗衣机",
          },
          {
            num: "0723",
            name: "家用电动打蛋器",
          },
          {
            num: "0723",
            name: "家用电动榨水果机",
          },
          {
            num: "0752",
            name: "清洁用吸尘装置",
          },
          {
            num: "0709",
            name: "电动意式面食制作机",
          },
          {
            num: "0709",
            name: "奶油机",
          },
          {
            num: "0723",
            name: "电动螺旋切菜机",
          },
          {
            num: "0742",
            name: "磨刀机",
          },
        ],
        company: "苹果（中国）有限公司",
        type_num: "7",
        agent: "直接办理",
        steps: [
          {
            date: "2022-08-01",
            step: "商标注册申请-申请收文",
          },
          {
            date: "2022-08-19",
            step: "商标注册申请-等待受理通知书发文",
          },
          {
            date: "2023-03-13",
            step: "商标注册申请-等待注册证发文",
          },
        ],
        apply_date: "2022-08-01",
      },
      {
        reg_number: "66308467",
        period: "-",
        id: "62ea03d0fbf1891d8f5e56ac",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/f875d1019abce1ffa8a67ece384b2c08.jpg",
        name: "AEMAPE",
        status: "等待实质审查",
        type_name: "手工器械",
        products: [
          {
            num: "0808",
            name: "电熨斗",
          },
          {
            num: "0806",
            name: "剃须刀",
          },
          {
            num: "0808",
            name: "烫皱褶用熨斗",
          },
          {
            num: "0806",
            name: "烫发钳",
          },
          {
            num: "0806",
            name: "电动理发器",
          },
          {
            num: "0810",
            name: "剪刀",
          },
          {
            num: "0810",
            name: "切菜刀",
          },
          {
            num: "0812",
            name: "餐具（刀、叉和匙）",
          },
          {
            num: "0801",
            name: "磨刀器具",
          },
          {
            num: "0806",
            name: "非医用激光脱毛装置",
          },
        ],
        company: "苹果（中国）有限公司",
        type_num: "8",
        agent: "直接办理",
        steps: [
          {
            date: "2022-08-01",
            step: "商标注册申请-申请收文",
          },
          {
            date: "2022-08-19",
            step: "商标注册申请-等待受理通知书发文",
          },
          {
            date: "2022-12-17",
            step: "商标注册申请-等待驳回通知发文",
          },
          {
            date: "2023-02-11",
            step: "商标注册申请-等待驳回复审",
          },
        ],
        apply_date: "2022-08-01",
      },
      {
        reg_number: "66308461",
        period: "-",
        id: "62ea03c5b57f0149ac9adeb2",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/68a97716c142dc66c387adacc3e9e3e9.jpg",
        name: "图形",
        status: "等待实质审查",
        type_name: "灯具空调",
        products: [
          {
            num: "1109",
            name: "沐浴用设备",
          },
          {
            num: "1109",
            name: "淋浴热水器",
          },
          {
            num: "1109",
            name: "抽水马桶",
          },
          {
            num: "1107",
            name: "加热装置",
          },
          {
            num: "1105",
            name: "冰箱",
          },
          {
            num: "1104",
            name: "电力煮咖啡机",
          },
          {
            num: "1106",
            name: "空气调节装置",
          },
          {
            num: "1106",
            name: "厨房用抽油烟机",
          },
          {
            num: "1106",
            name: "个人用电风扇",
          },
          {
            num: "1104",
            name: "空气炸锅",
          },
          {
            num: "1110",
            name: "饮水机",
          },
          {
            num: "1106",
            name: "电干衣机",
          },
          {
            num: "1104",
            name: "烘烤器具",
          },
          {
            num: "1110",
            name: "消毒碗柜",
          },
          {
            num: "1101",
            name: "灯",
          },
          {
            num: "1110",
            name: "污水净化设备",
          },
          {
            num: "1106",
            name: "织物蒸汽挂烫机",
          },
          {
            num: "1104",
            name: "电热水壶",
          },
          {
            num: "1104",
            name: "冷热饮料机（加热或制冷）",
          },
          {
            num: "1105",
            name: "冷热饮料机（加热或制冷）",
          },
          {
            num: "1110",
            name: "饮用水过滤设备",
          },
          {
            num: "1111",
            name: "电暖器",
          },
          {
            num: "1106",
            name: "加湿器",
          },
          {
            num: "1104",
            name: "微波炉（厨房用具）",
          },
          {
            num: "1111",
            name: "暖足器（电或非电的）",
          },
          {
            num: "1106",
            name: "气体净化装置",
          },
          {
            num: "1105",
            name: "冰柜",
          },
          {
            num: "1110",
            name: "水净化装置",
          },
          {
            num: "1104",
            name: "电炊具",
          },
          {
            num: "1104",
            name: "电油炸锅",
          },
        ],
        company: "苹果（中国）有限公司",
        type_num: "11",
        agent: "直接办理",
        steps: [
          {
            date: "2022-08-01",
            step: "商标注册申请-申请收文",
          },
          {
            date: "2022-08-19",
            step: "商标注册申请-等待受理通知书发文",
          },
          {
            date: "2022-12-08",
            step: "商标注册申请-等待驳回通知发文",
          },
          {
            date: "2023-02-02",
            step: "商标注册申请-等待驳回复审",
          },
        ],
        apply_date: "2022-08-01",
      },
      {
        reg_number: "66308460",
        period: "2023年02月14日至2033年02月13日",
        id: "62ea03d6e4d17dcb8a8a85ff",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/4042c126bcf0b808b33c818b1d6f657f.jpg",
        name: "AEMAPE",
        status: "注册商标",
        type_name: "机械设备",
        products: [
          {
            num: "0723",
            name: "搅拌机",
          },
          {
            num: "0733",
            name: "搅拌机",
          },
          {
            num: "0709",
            name: "搅拌机",
          },
          {
            num: "0723",
            name: "洗碗机",
          },
          {
            num: "0723",
            name: "非手动磨咖啡机",
          },
          {
            num: "0724",
            name: "洗衣机",
          },
          {
            num: "0723",
            name: "家用电动打蛋器",
          },
          {
            num: "0723",
            name: "家用电动榨水果机",
          },
          {
            num: "0752",
            name: "清洁用吸尘装置",
          },
          {
            num: "0709",
            name: "电动意式面食制作机",
          },
          {
            num: "0709",
            name: "奶油机",
          },
          {
            num: "0723",
            name: "电动螺旋切菜机",
          },
          {
            num: "0742",
            name: "磨刀机",
          },
        ],
        company: "苹果（中国）有限公司",
        type_num: "7",
        agent: "直接办理",
        steps: [
          {
            date: "2022-08-01",
            step: "商标注册申请-申请收文",
          },
          {
            date: "2022-08-19",
            step: "商标注册申请-等待受理通知书发文",
          },
          {
            date: "2023-03-23",
            step: "商标注册申请-等待注册证发文",
          },
        ],
        apply_date: "2022-08-01",
      },
    ],
  },
  {
    creditNo: "91350500768588454G",
    brands: [
      {
        reg_number: "44372137",
        period: "2021年03月28日至2031年03月27日",
        id: "611c45f8155bfe2e4553ca31",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/8d4d50bcea365619de6d1c7aac43cf69.jpg",
        name: "JINPINGGUO",
        status: "注册",
        type_name: "服装鞋帽",
        products: [
          {
            num: "2507",
            name: "足球鞋",
          },
          {
            num: "2507",
            name: "鞋",
          },
        ],
        company: "金苹果（中国）有限公司",
        type_num: "25",
        agent: "福建亚太商标专利事务所有限公司",
        steps: [
          {
            date: "2020-03-28",
            step: "商标注册申请-受理通知书发文",
          },
          {
            date: "2020-03-04",
            step: "商标注册申请-申请收文",
          },
          {
            date: "2020-10-13",
            step: "商标注册申请-驳回通知发文",
          },
          {
            date: "2020-12-08",
            step: "商标注册申请-等待驳回复审",
          },
          {
            date: "2021-05-12",
            step: "商标注册申请-注册证发文",
          },
        ],
        apply_date: "2020-03-04",
      },
      {
        reg_number: "44348963",
        period: "2020年11月28日至2030年11月27日",
        id: "611c46e2d4ee1432f88cd5d7",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/0eec0f4d94c11393a1a4426728c49637.jpg",
        name: "J",
        status: "注册",
        type_name: "服装鞋帽",
        products: [
          {
            num: "2507",
            name: "鞋",
          },
          {
            num: "2504",
            name: "服装",
          },
          {
            num: "2511",
            name: "围巾",
          },
          {
            num: "2502",
            name: "服装",
          },
          {
            num: "2510",
            name: "手套（服装）",
          },
          {
            num: "2503",
            name: "服装",
          },
          {
            num: "2505",
            name: "服装",
          },
          {
            num: "2508",
            name: "帽子",
          },
          {
            num: "2501",
            name: "童装",
          },
          {
            num: "2503",
            name: "游泳衣",
          },
          {
            num: "2511",
            name: "领带",
          },
          {
            num: "2509",
            name: "袜",
          },
          {
            num: "2501",
            name: "服装",
          },
          {
            num: "2507",
            name: "足球鞋",
          },
        ],
        company: "金苹果（中国）有限公司",
        type_num: "25",
        agent: "福建亚太商标专利事务所有限公司",
        steps: [
          {
            date: "2020-03-04",
            step: "商标注册申请-申请收文",
          },
          {
            date: "2020-11-27",
            step: "商标异议申请-申请收文",
          },
          {
            date: "2020-03-28",
            step: "商标注册申请-受理通知书发文",
          },
          {
            date: "2021-03-30",
            step: "商标异议申请-受理通知发文",
          },
          {
            date: "2021-04-13",
            step: "商标异议申请-打印异议答辩清单",
          },
          {
            date: "2021-03-30",
            step: "商标异议申请-打印答辩通知书",
          },
          {
            date: "2021-10-15",
            step: "商标异议申请-裁定书发文",
          },
          {
            date: "2021-12-03",
            step: "商标异议申请-注册发文",
          },
        ],
        apply_date: "2020-03-04",
      },
      {
        reg_number: "44357364",
        period: "-",
        id: "611c45febc237d648d1eacff",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/6d35d6a7f399a350f2493785e54cba8c.jpg",
        name: "图形",
        status: "驳回复审中",
        type_name: "服装鞋帽",
        products: [
          {
            num: "2510",
            name: "手套（服装）",
          },
          {
            num: "2501",
            name: "服装",
          },
          {
            num: "2503",
            name: "游泳衣",
          },
          {
            num: "2501",
            name: "童装",
          },
          {
            num: "2505",
            name: "服装",
          },
          {
            num: "2511",
            name: "领带",
          },
          {
            num: "2508",
            name: "帽子",
          },
          {
            num: "2511",
            name: "围巾",
          },
          {
            num: "2504",
            name: "服装",
          },
          {
            num: "2509",
            name: "袜",
          },
          {
            num: "2507",
            name: "鞋",
          },
          {
            num: "2502",
            name: "服装",
          },
          {
            num: "2503",
            name: "服装",
          },
          {
            num: "2507",
            name: "足球鞋",
          },
        ],
        company: "金苹果（中国）有限公司",
        type_num: "25",
        agent: "福建亚太商标专利事务所有限公司",
        steps: [
          {
            date: "2020-03-04",
            step: "商标注册申请-申请收文",
          },
          {
            date: "2020-03-28",
            step: "商标注册申请-受理通知书发文",
          },
          {
            date: "2021-01-12",
            step: "驳回复审-评审分案",
          },
          {
            date: "2020-10-09",
            step: "驳回复审-申请收文",
          },
          {
            date: "2020-09-08",
            step: "商标注册申请-驳回通知发文",
          },
          {
            date: "2021-02-22",
            step: "驳回复审-实审裁文发文",
          },
        ],
        apply_date: "2020-03-04",
      },
      {
        reg_number: "44369982",
        period: "2020年12月07日至2030年12月06日",
        id: "611c45ef373a70418d285034",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/27e91a06d4a6c9005de110c2a6476fef.jpg",
        name: "E.T",
        status: "注册",
        type_name: "服装鞋帽",
        products: [
          {
            num: "2503",
            name: "游泳衣",
          },
          {
            num: "2510",
            name: "手套（服装）",
          },
          {
            num: "2508",
            name: "帽子",
          },
          {
            num: "2501",
            name: "服装",
          },
          {
            num: "2512",
            name: "皮带（服饰用）",
          },
          {
            num: "2504",
            name: "服装",
          },
          {
            num: "2507",
            name: "鞋",
          },
          {
            num: "2501",
            name: "童装",
          },
          {
            num: "2505",
            name: "服装",
          },
          {
            num: "2511",
            name: "围巾",
          },
          {
            num: "2509",
            name: "袜",
          },
          {
            num: "2503",
            name: "服装",
          },
          {
            num: "2511",
            name: "领带",
          },
          {
            num: "2502",
            name: "服装",
          },
        ],
        company: "金苹果（中国）有限公司",
        type_num: "25",
        agent: "福建亚太商标专利事务所有限公司",
        steps: [
          {
            date: "2020-03-28",
            step: "商标注册申请-受理通知书发文",
          },
          {
            date: "2020-03-04",
            step: "商标注册申请-申请收文",
          },
          {
            date: "2021-01-12",
            step: "商标注册申请-注册证发文",
          },
        ],
        apply_date: "2020-03-04",
      },
    ],
  },
  {
    creditNo: "91110108558521630L",
    brands: [
      {
        reg_number: "44372137",
        period: "2021年03月28日至2031年03月27日",
        id: "611c45f8155bfe2e4553ca31",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/8d4d50bcea365619de6d1c7aac43cf69.jpg",
        name: "JINPINGGUO",
        status: "注册",
        type_name: "服装鞋帽",
        products: [
          {
            num: "2507",
            name: "足球鞋",
          },
          {
            num: "2507",
            name: "鞋",
          },
        ],
        company: "金苹果（中国）有限公司",
        type_num: "25",
        agent: "福建亚太商标专利事务所有限公司",
        steps: [
          {
            date: "2020-03-28",
            step: "商标注册申请-受理通知书发文",
          },
          {
            date: "2020-03-04",
            step: "商标注册申请-申请收文",
          },
          {
            date: "2020-10-13",
            step: "商标注册申请-驳回通知发文",
          },
          {
            date: "2020-12-08",
            step: "商标注册申请-等待驳回复审",
          },
          {
            date: "2021-05-12",
            step: "商标注册申请-注册证发文",
          },
        ],
        apply_date: "2020-03-04",
      },
    ],
  },
  {
    creditNo: "914420006947806297",
    brands: [
      {
        reg_number: "44277953",
        period: "-",
        id: "611c2f3d45f7e88531c2bc0d",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/c91a8b94ecf35ee4bda9e05199a2f686.jpg",
        name: "XIAOMI",
        status: "申请被驳回、不予受理等，该商标已失效",
        type_name: "机械设备",
        products: [],
        company: "中山市西沐电器有限公司",
        type_num: "7",
        agent: "北京世纪鼎力国际知识产权代理有限公司",
        steps: [
          {
            date: "2020-03-24",
            step: "商标注册申请-受理通知书发文",
          },
          {
            date: "2020-09-29",
            step: "商标注册申请-驳回通知发文",
          },
          {
            date: "2020-02-28",
            step: "商标注册申请-申请收文",
          },
          {
            date: "2020-11-24",
            step: "商标注册申请-等待驳回复审",
          },
        ],
        apply_date: "2020-02-28",
      },
      {
        reg_number: "44272924",
        period: "-",
        id: "611c2ea296ec8b7936c23b63",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/52b314e3506bcd1a8b9c034a73151777.jpg",
        name: "小米",
        status: "申请被驳回、不予受理等，该商标已失效",
        type_name: "灯具空调",
        products: [],
        company: "中山市西沐电器有限公司",
        type_num: "11",
        agent: "北京世纪鼎力国际知识产权代理有限公司",
        steps: [
          {
            date: "2021-01-12",
            step: "商标注册申请-等待驳回复审",
          },
          {
            date: "2020-03-25",
            step: "商标注册申请-受理通知书发文",
          },
          {
            date: "2020-02-28",
            step: "商标注册申请-申请收文",
          },
          {
            date: "2020-11-17",
            step: "商标注册申请-驳回通知发文",
          },
        ],
        apply_date: "2020-02-28",
      },
      {
        reg_number: "44278289",
        period: "-",
        id: "611c2f518025f6add68e7f8a",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/3112d008787c0afec1adad31303524ce.jpg",
        name: "小米",
        status: "等待实质审查",
        type_name: "机械设备",
        products: [
          {
            num: "0709",
            name: "奶油机",
          },
          {
            num: "0724",
            name: "洗衣用甩干机",
          },
          {
            num: "0710",
            name: "电动制饮料机",
          },
          {
            num: "0710",
            name: "酿葡萄酒用压榨机",
          },
          {
            num: "0702",
            name: "水族池通气泵",
          },
          {
            num: "0723",
            name: "家用非手动研磨机",
          },
          {
            num: "0723",
            name: "家用电动榨水果机",
          },
          {
            num: "0724",
            name: "洗衣机",
          },
          {
            num: "0752",
            name: "真空吸尘器",
          },
          {
            num: "0713",
            name: "熨衣机",
          },
          {
            num: "0723",
            name: "家用电动搅拌机",
          },
        ],
        company: "中山市西沐电器有限公司",
        type_num: "7",
        agent: "北京世纪鼎力国际知识产权代理有限公司",
        steps: [
          {
            date: "2020-02-28",
            step: "商标注册申请-申请收文",
          },
          {
            date: "2020-03-24",
            step: "商标注册申请-受理通知书发文",
          },
          {
            date: "2020-10-17",
            step: "商标注册申请-驳回通知发文",
          },
          {
            date: "2020-12-12",
            step: "商标注册申请-等待驳回复审",
          },
        ],
        apply_date: "2020-02-28",
      },
    ],
  },
  {
    creditNo: "91110302587727910P",
    brands: [],
  },
  {
    creditNo: "91110400MA04ENG447",
    brands: [],
  },
  {
    creditNo: "91330103580267747M",
    brands: [
      {
        reg_number: "71363954",
        period: "-",
        id: "64630c70a68b8a690de04d24",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/ce0158e3abe32a3bd5be06f4c11286bc.jpg",
        name: "小米环境",
        status: "-",
        type_name: "建筑材料",
        products: [
          {
            num: "1907",
            name: "非金属耐火建筑材料",
          },
          {
            num: "1909",
            name: "非金属、非塑料制水管阀",
          },
          {
            num: "1909",
            name: "非金属建筑材料",
          },
          {
            num: "1909",
            name: "非金属排水管",
          },
          {
            num: "1909",
            name: "建筑用非金属硬管",
          },
          {
            num: "1909",
            name: "非金属分岔管",
          },
          {
            num: "1909",
            name: "非金属通风管道",
          },
          {
            num: "1909",
            name: "通风和空调设备用非金属管",
          },
          {
            num: "1909",
            name: "非金属水管",
          },
          {
            num: "1910",
            name: "非金属建筑物",
          },
        ],
        company: "杭州小米环境科技有限公司",
        type_num: "19",
        agent: "-",
        steps: [],
        apply_date: "2023-05-06",
      },
      {
        reg_number: "71352683",
        period: "-",
        id: "64630c5f2386e772bcf4f65a",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/47ea093dc6b3e7a743a7b46a6b5c5a0a.jpg",
        name: "小米环境",
        status: "等待实质审查",
        type_name: "金属材料",
        products: [
          {
            num: "0602",
            name: "通风用金属管",
          },
          {
            num: "0602",
            name: "金属水管阀",
          },
          {
            num: "0602",
            name: "通风和空调设备用金属管",
          },
          {
            num: "0602",
            name: "金属制管套筒",
          },
          {
            num: "0602",
            name: "金属阀门（非机器零件）",
          },
          {
            num: "0602",
            name: "金属管道",
          },
          {
            num: "0602",
            name: "管道用金属接头",
          },
          {
            num: "0602",
            name: "管道用金属连接器",
          },
          {
            num: "0607",
            name: "金属环",
          },
          {
            num: "0613",
            name: "金属密封盖",
          },
        ],
        company: "杭州小米环境科技有限公司",
        type_num: "6",
        agent: "杭州凯知专利代理事务所（普通合伙）",
        steps: [
          {
            date: "2023-06-09",
            step: "商标注册申请-等待受理通知书发文",
          },
          {
            date: "2023-05-06",
            step: "商标注册申请-申请收文",
          },
        ],
        apply_date: "2023-05-06",
      },
      {
        reg_number: "55688903",
        period: "2021年11月21日至2031年11月20日",
        id: "611c90f7a6164a1369a744d3",
        image_name:
          "https://qxb-img-osscache.qixin.com/official_trademark/bee695ed6710bed7838bc2787d92bbf1.jpg",
        name: "厨康",
        status: "注册",
        type_name: "建筑材料",
        products: [
          {
            num: "1909",
            name: "非金属通风管道",
          },
          {
            num: "1909",
            name: "建筑用非金属制墙包层",
          },
          {
            num: "1909",
            name: "陶土制管和管道",
          },
          {
            num: "1909",
            name: "灌溉用塑料管道",
          },
          {
            num: "1909",
            name: "非金属下水管道",
          },
          {
            num: "1909",
            name: "墙用非金属包层（建筑）",
          },
          {
            num: "1909",
            name: "非金属或非塑料排水阱（阀）",
          },
          {
            num: "1909",
            name: "地下室防水系统建造用非金属排水管",
          },
          {
            num: "1909",
            name: "非金属檐板",
          },
          {
            num: "1909",
            name: "砂石管道",
          },
        ],
        company: "杭州小米环境科技有限公司",
        type_num: "19",
        agent: "苏州中细软知识产权运营有限公司",
        steps: [
          {
            date: "2021-05-25",
            step: "商标注册申请-受理通知书发文",
          },
          {
            date: "2021-04-29",
            step: "商标注册申请-申请收文",
          },
          {
            date: "2021-12-20",
            step: "商标注册申请-注册证发文",
          },
        ],
        apply_date: "2021-04-29",
      },
    ],
  },
];
export default brands;
