const trademarkInfo = [
  {
    steps: [],
    first_pubno: "-",
    image_name: "-",
    status: "-",
    company_en: "-",
    url: "-",
    agent: "-",
    created_time: "2023-06-08 18:29:26",
    address_en: "-",
    is_shared: "0",
    priority_date: "-",
    org_image_url: "http://wcjs.sbj.cnipa.gov.cn/thumbnail/52/063/71943557.jpg",
    color: "-",
    company: "小米科技有限责任公司",
    type_num: "9",
    last_special_date: "-",
    address_cn: "-",
    start_date: "-",
    reg_pubdate: "-",
    image_url:
      "https://qxb-img-osscache.qixin.com/official_trademark/bec2bb85e6bc266ad9ba7b483a4b3135.jpg",
    reg_pubno: "-",
    trademarktype: "-",
    period: "-",
    ename: "小米科技有限责任公司",
    notices: [],
    global_date: "-",
    name: "未家",
    apply_date: "2023-05-31",
    products: [
      {
        num: "0901",
        name: "智能手表（数据处理）",
      },
      {
        num: "0901",
        name: "操作系统程序",
      },
      {
        num: "0901",
        name: "接入或进入控制用计算机程序",
      },
      {
        num: "0901",
        name: "可下载的计算机应用软件",
      },
      {
        num: "0902",
        name: "计步器",
      },
      {
        num: "0907",
        name: "穿戴式行动追踪器",
      },
      {
        num: "0907",
        name: "手机",
      },
      {
        num: "0908",
        name: "虚拟现实眼镜",
      },
      {
        num: "0908",
        name: "智能音箱",
      },
      {
        num: "0909",
        name: "数字投影仪",
      },
      {
        num: "0910",
        name: "测量器械和仪器",
      },
      {
        num: "0912",
        name: "电源材料（电线、电缆）",
      },
      {
        num: "0913",
        name: "芯片（集成电路）",
      },
      {
        num: "0913",
        name: "传感器",
      },
      {
        num: "0913",
        name: "遥控装置",
      },
      {
        num: "0913",
        name: "视频显示屏",
      },
      {
        num: "0920",
        name: "报警器",
      },
      {
        num: "0920",
        name: "生物指纹门锁",
      },
      {
        num: "0921",
        name: "3D眼镜",
      },
      {
        num: "0922",
        name: "电池充电器",
      },
    ],
    eid: "534472fd-7d53-4958-8132-d6a6242423d8",
    end_date: "-",
    last_updated_time: "2023-06-09 16:12:18",
    first_pubdate: "-",
    reg_number: "71943557",
    element: "-",
  },
  {
    steps: [],
    first_pubno: "-",
    image_name: "-",
    status: "-",
    company_en: "-",
    url: "-",
    agent: "-",
    created_time: "2023-06-08 00:33:12",
    address_en: "-",
    is_shared: "0",
    priority_date: "-",
    org_image_url: "http://wcjs.sbj.cnipa.gov.cn/thumbnail/84/fdd/71908473.jpg",
    color: "-",
    company: "小米科技有限责任公司",
    type_num: "25",
    last_special_date: "-",
    address_cn: "-",
    start_date: "-",
    reg_pubdate: "-",
    image_url:
      "https://qxb-img-osscache.qixin.com/official_trademark/e4134825fa7b6fc21a194950557e9413.jpg",
    reg_pubno: "-",
    trademarktype: "-",
    period: "-",
    ename: "小米科技有限责任公司",
    notices: [],
    global_date: "-",
    name: "XIAOMI",
    apply_date: "2023-05-30",
    products: [
      {
        num: "2501",
        name: "成品衣",
      },
      {
        num: "2501",
        name: "服装",
      },
      {
        num: "2502",
        name: "婴儿全套衣",
      },
      {
        num: "2502",
        name: "服装",
      },
      {
        num: "2503",
        name: "驾驶员服装",
      },
      {
        num: "2503",
        name: "服装",
      },
      {
        num: "2504",
        name: "服装",
      },
      {
        num: "2504",
        name: "防雨夹克",
      },
      {
        num: "2505",
        name: "服装",
      },
      {
        num: "2505",
        name: "化装舞会用服装",
      },
      {
        num: "2507",
        name: "鞋",
      },
      {
        num: "2508",
        name: "帽子",
      },
      {
        num: "2509",
        name: "袜",
      },
      {
        num: "2510",
        name: "手套（服装）",
      },
      {
        num: "2511",
        name: "围巾",
      },
      {
        num: "2512",
        name: "衣服背带",
      },
      {
        num: "2513",
        name: "浴帽",
      },
      {
        num: "2513",
        name: "婚纱",
      },
      {
        num: "2513",
        name: "理发用披肩",
      },
      {
        num: "2513",
        name: "服装绶带",
      },
      {
        num: "2513",
        name: "睡眠用眼罩",
      },
      {
        num: "2513",
        name: "神父左臂上佩戴的饰带",
      },
    ],
    eid: "534472fd-7d53-4958-8132-d6a6242423d8",
    end_date: "-",
    last_updated_time: "2023-06-09 02:54:50",
    first_pubdate: "-",
    reg_number: "71908473",
    element: "-",
  },
  {
    steps: [],
    first_pubno: "-",
    image_name: "-",
    status: "-",
    company_en: "-",
    url: "-",
    agent: "-",
    created_time: "2023-06-07 17:26:34",
    address_en: "-",
    is_shared: "0",
    priority_date: "-",
    org_image_url: "http://wcjs.sbj.cnipa.gov.cn/thumbnail/34/676/71915546.jpg",
    color: "-",
    company: "小米科技有限责任公司",
    type_num: "6",
    last_special_date: "-",
    address_cn: "-",
    start_date: "-",
    reg_pubdate: "-",
    image_url:
      "https://qxb-img-osscache.qixin.com/official_trademark/719d1b116525f07e3c05ad0b371d184e.jpg",
    reg_pubno: "-",
    trademarktype: "-",
    period: "-",
    ename: "小米科技有限责任公司",
    notices: [],
    global_date: "-",
    name: "小米米家",
    apply_date: "2023-05-30",
    products: [
      {
        num: "0601",
        name: "3D打印机用金属箔或金属粉",
      },
      {
        num: "0602",
        name: "金属喷头",
      },
      {
        num: "0602",
        name: "金属管道",
      },
      {
        num: "0603",
        name: "具有隔音效果的金属建筑材料",
      },
      {
        num: "0604",
        name: "金属轨道",
      },
      {
        num: "0607",
        name: "金属螺丝",
      },
      {
        num: "0608",
        name: "金属关门器（非电动）",
      },
      {
        num: "0610",
        name: "金属挂锁（非电子）",
      },
      {
        num: "0611",
        name: "保险箱（金属或非金属）",
      },
      {
        num: "0614",
        name: "金属车牌",
      },
    ],
    eid: "534472fd-7d53-4958-8132-d6a6242423d8",
    end_date: "-",
    last_updated_time: "2023-06-08 20:05:08",
    first_pubdate: "-",
    reg_number: "71915546",
    element: "-",
  },
  {
    steps: [
      {
        date: "2022-08-01",
        step: "商标注册申请-申请收文",
      },
      {
        date: "2022-08-19",
        step: "商标注册申请-等待受理通知书发文",
      },
      {
        date: "2022-12-08",
        step: "商标注册申请-等待驳回通知发文",
      },
      {
        date: "2023-02-02",
        step: "商标注册申请-等待驳回复审",
      },
    ],
    first_pubno: "-",
    image_name: "-",
    status: "等待实质审查",
    company_en: "-",
    url: "-",
    agent: "直接办理",
    created_time: "2022-08-03 13:12:35",
    address_en: "-",
    is_shared: "0",
    priority_date: "-",
    org_image_url: "http://wcjs.sbj.cnipa.gov.cn/image/18/acb/66308462.jpg",
    color: "-",
    company: "苹果（中国）有限公司",
    type_num: "9",
    last_special_date: "-",
    address_cn: "浙江省宁波杭州湾新区兴慈三路199号",
    start_date: "-",
    reg_pubdate: "-",
    image_url:
      "https://qxb-img-osscache.qixin.com/official_trademark/02add4ad85e133623f7f452e17062279.jpg",
    reg_pubno: "-",
    trademarktype: "一般",
    period: "-",
    ename: "苹果（中国）有限公司",
    notices: [],
    global_date: "-",
    name: "图形",
    apply_date: "2022-08-01",
    products: [
      {
        num: "0910",
        name: "电测量仪器",
      },
      {
        num: "0913",
        name: "电插头",
      },
      {
        num: "0913",
        name: "电开关",
      },
      {
        num: "0902",
        name: "数量显示器",
      },
      {
        num: "0904",
        name: "秤",
      },
      {
        num: "0910",
        name: "教学机器人",
      },
      {
        num: "0905",
        name: "规尺（量具）",
      },
      {
        num: "0920",
        name: "电动防盗装置",
      },
      {
        num: "0906",
        name: "防交通事故用穿戴式反射用品",
      },
      {
        num: "0909",
        name: "液晶投影机",
      },
      {
        num: "0919",
        name: "安全头盔",
      },
      {
        num: "0910",
        name: "联机手环（测量仪器）",
      },
      {
        num: "0912",
        name: "电线",
      },
      {
        num: "0911",
        name: "望远镜",
      },
      {
        num: "0913",
        name: "插线板",
      },
      {
        num: "0919",
        name: "护目镜",
      },
      {
        num: "0919",
        name: "防弹衣",
      },
      {
        num: "0922",
        name: "电池",
      },
      {
        num: "0922",
        name: "蓄电池",
      },
      {
        num: "0922",
        name: "发电用太阳能电池板",
      },
      {
        num: "0922",
        name: "无线充电器",
      },
      {
        num: "0910",
        name: "电度表",
      },
      {
        num: "0921",
        name: "眼镜",
      },
      {
        num: "0901",
        name: "编辑图像、声音和视频用计算机程序",
      },
      {
        num: "0901",
        name: "智能手表（数据处理）",
      },
      {
        num: "0908",
        name: "电视机",
      },
    ],
    eid: "9e3eb0d8-d77e-40e0-a3dd-7020231841c4",
    end_date: "-",
    last_updated_time: "2023-04-26 10:09:52",
    first_pubdate: "-",
    reg_number: "66308462",
    element: "-",
  },
  {
    steps: [
      {
        date: "2022-12-31",
        step: "商标注册申请-等待驳回复审",
      },
      {
        date: "2022-12-08",
        step: "商标注册申请-等待驳回通知发文",
      },
      {
        date: "2022-08-19",
        step: "商标注册申请-等待受理通知书发文",
      },
      {
        date: "2022-08-01",
        step: "商标注册申请-申请收文",
      },
      {
        date: "2023-05-18",
        step: "商标注册申请-等待注册证发文",
      },
    ],
    first_pubno: "1823",
    image_name: "-",
    status: "注册商标",
    company_en: "-",
    url: "-",
    agent: "直接办理",
    created_time: "2023-01-02 00:38:18",
    address_en: "-",
    is_shared: "0",
    priority_date: "-",
    org_image_url:
      "http://wcjs.sbj.cnipa.gov.cn/thumbnail/d5/779/66308461A.jpg",
    color: "-",
    company: "苹果（中国）有限公司",
    type_num: "11",
    last_special_date: "-",
    address_cn: "浙江省宁波杭州湾新区兴慈三路199号",
    start_date: "2023-04-14",
    reg_pubdate: "2023-04-14",
    image_url:
      "https://qxb-img-osscache.qixin.com/official_trademark/625d31bf73122eab2873f0bef8a61d53.jpg",
    reg_pubno: "1835",
    trademarktype: "一般",
    period: "2023年04月14日至2033年04月13日",
    ename: "苹果（中国）有限公司",
    notices: [
      {
        content: "2023-01-13 商标初步审定公告 第1823期",
        date: "2023-01-13",
        url: "https://qxb-img-osscache.qixin.com/official_trademark_announce_image/64ba4bb03055bd6c9d240c60df078963.jpg",
      },
    ],
    global_date: "-",
    name: "图形",
    apply_date: "2022-08-01",
    products: [
      {
        num: "1107",
        name: "加热装置",
      },
    ],
    eid: "9e3eb0d8-d77e-40e0-a3dd-7020231841c4",
    end_date: "2033-04-13",
    last_updated_time: "2023-05-21 22:02:23",
    first_pubdate: "2023-01-13",
    reg_number: "66308461A",
    element: "-",
  },
  {
    steps: [
      {
        date: "2022-08-01",
        step: "商标注册申请-申请收文",
      },
      {
        date: "2022-08-19",
        step: "商标注册申请-等待受理通知书发文",
      },
      {
        date: "2023-03-13",
        step: "商标注册申请-等待注册证发文",
      },
    ],
    first_pubno: "1813",
    image_name: "-",
    status: "注册商标",
    company_en: "-",
    url: "-",
    agent: "直接办理",
    created_time: "2022-08-03 13:12:45",
    address_en: "-",
    is_shared: "0",
    priority_date: "-",
    org_image_url: "http://wcjs.sbj.cnipa.gov.cn/image/21/61b/66308464.jpg",
    color: "-",
    company: "苹果（中国）有限公司",
    type_num: "7",
    last_special_date: "-",
    address_cn: "浙江省宁波杭州湾新区兴慈三路199号",
    start_date: "2023-01-28",
    reg_pubdate: "2023-01-28",
    image_url:
      "https://qxb-img-osscache.qixin.com/official_trademark/7a14f246f6b48700916d6fa64032f3ba.jpg",
    reg_pubno: "1825",
    trademarktype: "一般",
    period: "2023年01月28日至2033年01月27日",
    ename: "苹果（中国）有限公司",
    notices: [
      {
        content: "2022-10-27 商标初步审定公告 第1813期",
        date: "2022-10-27",
        url: "https://qxb-img-osscache.qixin.com/official_trademark_announce_image/3d3b95de18c37522df618b3afeb22209.jpg",
      },
      {
        content: "2023-01-27 商标注册公告（一） 第1825期",
        date: "2023-01-27",
        url: "https://qxb-img-osscache.qixin.com/official_trademark_announce_image/13ba62552d16545b731473dae8da2a34.jpg",
      },
    ],
    global_date: "-",
    name: "图形",
    apply_date: "2022-08-01",
    products: [
      {
        num: "0723",
        name: "搅拌机",
      },
      {
        num: "0733",
        name: "搅拌机",
      },
      {
        num: "0709",
        name: "搅拌机",
      },
      {
        num: "0723",
        name: "洗碗机",
      },
      {
        num: "0723",
        name: "非手动磨咖啡机",
      },
      {
        num: "0724",
        name: "洗衣机",
      },
      {
        num: "0723",
        name: "家用电动打蛋器",
      },
      {
        num: "0723",
        name: "家用电动榨水果机",
      },
      {
        num: "0752",
        name: "清洁用吸尘装置",
      },
      {
        num: "0709",
        name: "电动意式面食制作机",
      },
      {
        num: "0709",
        name: "奶油机",
      },
      {
        num: "0723",
        name: "电动螺旋切菜机",
      },
      {
        num: "0742",
        name: "磨刀机",
      },
    ],
    eid: "9e3eb0d8-d77e-40e0-a3dd-7020231841c4",
    end_date: "2033-01-27",
    last_updated_time: "2023-02-02 18:20:04",
    first_pubdate: "2022-10-27",
    reg_number: "66308464",
    element: "-",
  },
];

export default trademarkInfo;
