import { Row, Col, Statistic, Button } from "antd";
import styles from "./kanban.module.css";
import {
  Chart1,
  Chart2,
  Chart3,
  Chart4,
  Chart5,
  Chart6,
} from "../../components/charts";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";

const span = { xs: 24, sm: 24, md: 24, lg: 12, xl: 8, xxl: 8 };

export default function Kanban1() {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader
        title="企业数据统计"
        description="针对入驻园区的企业、高新技术企业、老字号企业、经营异常企业等数据的统计看板。"
      />
      <div className={styles.timestamp}>
        最后更新时间：2023年6月12日 23:00:00
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className={styles.section}>
            <div className={styles.statistic}>
              <div className={styles.item}>
                <Statistic title="企业数量" value={433} />
                <Button
                  type="primary"
                  onClick={() => navigate("/1/query/companies")}
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="高新技术企业数量" value={27} />
                <Button
                  type="primary"
                  onClick={() => navigate("/1/query/companies")}
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="老子号企业数量" value={0} />
                <Button
                  type="primary"
                  onClick={() => navigate("/1/query/companies")}
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="经营异常企业数量" value={3} />
                <Button
                  type="primary"
                  onClick={() => navigate("/1/query/companies")}
                >
                  查看详情
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>企业所属行业</h2>
              <Chart1 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>企业注册资金</h2>
              <Chart2 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>企业入驻园区数量（近5年）</h2>
              <Chart3 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>高新技术企业数量（近5年）</h2>
              <Chart4 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>老字号企业数量（近5年）</h2>
              <Chart5 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>经营异常企业数量（近5年）</h2>
              <Chart6 />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
